export const i18n_contribute_en = {
  meta_title: "Contribute your photo - grimaces.net",
  meta_description:
    "Contribute your photo and try to make it on the wall of fame. Usually we delete all photos after the game but if you like you can save it - maybe you make it to the wall of fame!",
  grimace_of: "Grimace of ",
  button_contribute: "I accept the terms below and contribute my photo",
  button_back: "Back to the game",
  briefing_headline: "Contribute your photo",
  briefing_text: `
    <p>This is your chance to make it to the wall of fame. You find it in the
     top right navigation and a subset of photos at the bottom of most pages.</p>
    <p>We review all contributed photos and if they are a good addition to the 
    wall of fame, we put it up there. As the wall contains a maximum of 100 photos 
    and we collected more grimaces than that, your photo might show up only once in a while.</p>
    <p>We also might use the content of the wall of fame for other purposes like spreading 
    the news about grimaces.net or advertising for the game.</p>
    <p>When you contribute the photo you grant us a non-exclusive and non-revokable 
    usage right for the photo. You also guarantee that it is a photo of you and that 
    you hold the rights to share it - not infriging anybody elses rights.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_contribute_de = {
  meta_title: "Teile Dein Foto - grimaces.net",
  meta_description:
    "Teile Dein Foto und versuch es in die Top 100 zu schaffen. Normalerweise löschen wir alle Fotos nach dem Spiel, aber wenn Du es teilst, bewahren wir es auf und vielleicht schaffst Du es in die Top 100!",
  grimace_of: "Grimasse von ",
  button_contribute: "Ich akzeptiere die Bedingungen unten und teile mein Foto",
  button_back: "Zurück zum Spiel",
  briefing_headline: "Teile Dein Foto",
  briefing_text: `
    <p>Das ist Deine Chance, es in die Top 100 zu schaffen! Du findest die Top 100 
    in der Navigation oben rechts.</p>
    <p>Normalerweise löschen wir alle Fotos nach dem Spiel, aber wenn Du es teilst, 
    bewahren wir es auf. Wir schauen alle geteilten Fotos an und die besten Grimassen 
    nehmen wir in die Top 100 auf.</p>
    <p>Neben den Top 100 nutzen wir die besten Grimassen auch für weitere Zwecke - zum 
    Beispiel, um über grimaces.net zu berichten oder für das Spiel zu werben.</p>
    <p>Mit dem Teilen des Bildes überträgst Du uns ein nicht-exklusives, nicht-widerrufbares 
    Nutzungsrecht für die Fotos. Außerdem versicherst Du noch einmal, keine Rechte Dritter 
    zu verletzen und dass Du selbst die Rechte hälst, uns die Nutzung zu erlauben. 
    Die detaillierten Nutzungsbedingungen findest Du auch oben rechts in der Navigation</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
