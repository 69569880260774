import React, { useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function Login(props) {
  const { setStoryboardStep, logInAs, logIn, setLogInAs } =
    useContext(GameContext);
  const [password, setPassword] = useState(logInAs);
  const { t } = useTranslation();

  function handleChange(e) {
    setPassword(e.target.value);
  }

  function forgotPwd() {
    setStoryboardStep("loginPwdForgot");
    trackingEvent("account", "login_forgotpwd");
  }

  function back2Login() {
    setLogInAs("");
    setStoryboardStep("login");
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("loginPwd.meta_title")}
        description={t("loginPwd.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="pwd"
          type="password"
          label={`${t("loginPwd.textfield_label")} ${logInAs}`}
          autoFocus
          variant="outlined"
          style={{ width: "100%" }}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.target.value) {
              logIn(password);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={password === ""}
          variant={"contained"}
          onClick={() => {
            logIn(password);
          }}
          style={{ width: "100%" }}
        >
          {t("loginPwd.button_login")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={back2Login}
          style={{ width: "100%" }}
        >
          {t("loginPwd.button_other_user")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={forgotPwd}
          style={{ width: "100%" }}
        >
          {t("loginPwd.button_pwd_forgot")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default Login;
