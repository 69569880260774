// import { consoleLog } from "./util/consoleLog";
import React, { useContext } from "react";
import { GameContext } from "./gameContext";

import MainMenu from "./mainMenu";
import grimacesLogoEn from "./img/grimace_logo_en.svg";

import Grid from "@mui/material/Grid";

function Header() {
  const { setStoryboardStep, logContext, photoAvatar } =
    useContext(GameContext);

  return (
    <Grid container spacing={1} alignItems="center" style={{ height: 70 }}>
      <Grid item xs={2}>
        <span
          onClick={() => setStoryboardStep("login")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={photoAvatar}
            style={{ height: 40, borderRadius: "10px" }}
            alt="grimace"
          />
        </span>
      </Grid>
      <Grid item xs={9} textAlign="center">
        <span onClick={() => logContext()}>
          <img
            // TODO: grimaces.net Das Grimassen-Spiel
            src={grimacesLogoEn}
            style={{ height: 33 }}
            alt="grimaces.net"
          />
        </span>
      </Grid>
      <Grid item xs={1} textAlign="right">
        <MainMenu />
      </Grid>
    </Grid>
  );
}

export default Header;
