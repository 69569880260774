import React, { useContext, useEffect } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

function StartGame(props) {
  const {
    logInAs,
    gameDuration,
    setGameDuration,
    gameStartTime,
    setCreateGame,
    setGameStartTime,
    setGameHost,
    setGameFinished,
    gameExpression,
    photos,
    // photosDispatch,
    setStoryboardStep,
    determineStoryboardStep,
    serverTimeNow,
  } = useContext(GameContext);

  const handleDurationChange = (event) => {
    setGameDuration(event.target.value);
    trackingEvent("game", "changeDuration", "", event.target.value / 60);
  };
  const { t } = useTranslation();

  const durationOptions = [
    { value: 60, text: t("startGame.duration_60") },
    { value: 180, text: t("startGame.duration_180") },
    { value: 600, text: t("startGame.duration_600") },
    { value: 1800, text: t("startGame.duration_1800") },
    { value: 3600, text: t("startGame.duration_3600") },
    { value: 10800, text: t("startGame.duration_10800") },
    { value: 21600, text: t("startGame.duration_21600") },
    { value: 86400, text: t("startGame.duration_86400") },
    { value: 259200, text: t("startGame.duration_259200") },
  ];

  const thisPhoto = photos.find((o) => o.user === logInAs);

  function startGame() {
    setGameFinished(false);
    setGameHost(logInAs);
    setGameStartTime(serverTimeNow());
    trackingEvent("game", "startGame");
    setCreateGame(true);
  }

  useEffect(() => {
    determineStoryboardStep();
    //eslint-disable-next-line
  }, [gameStartTime]);

  function shootAgain() {
    // photosDispatch({ type: "deleteAll" }); // removes photo which triggers determineStoryboardStep
    setStoryboardStep("photohost"); // goes back to takePhoto without deleting the photo
    trackingEvent("game", "shootAgain");
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("startGame.meta_title")}
        description={t("startGame.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="expression"
          value={gameExpression}
          label={t("startGame.textfield_label")}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        {thisPhoto ? (
          <img
            src={thisPhoto.jpg}
            style={{ maxWidth: "200px" }}
            alt="Grimace"
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid container item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="gameDuration">
            {t("startGame.select_duration_label")}
          </InputLabel>
          <Select
            labelId="gameDuration-label"
            id="gameDurationSelect"
            value={gameDuration}
            label={t("startGame.select_duration_label")}
            onChange={handleDurationChange}
          >
            {durationOptions.map((item) => {
              return (
                <MenuItem key={`duration${item.value}`} value={item.value}>
                  {item.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={startGame}
          style={{ width: "100%" }}
        >
          {t("startGame.button_start_game")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={shootAgain}
          style={{ width: "100%" }}
        >
          {t("startGame.button_shoot_again")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="startGame" />
      </Grid>
    </Grid>
  );
}
export default StartGame;
