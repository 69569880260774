import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";
import WallOfFame from "../support/wallOfFame";

function ParticipantNeedsSignUp(props) {
  const { setStoryboardStep } = useContext(GameContext);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("participant_needs_signup.meta_title")}
        description={t("participant_needs_signup.meta_description")}
      />
      <Grid item xs={12}>
        <BriefingI18n briefing="participant_needs_signup" />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={() => {
            setStoryboardStep("login");
          }}
          style={{ width: "100%" }}
        >
          {t("participant_needs_signup.button_proceed")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={15} />
      </Grid>
    </Grid>
  );
}

export default ParticipantNeedsSignUp;
