import { consoleLog } from "../util/consoleLog";
import { useEffect, useState } from "react";
import { getPhotoContributions } from "../util/apiConnect";
import Grid from "@mui/material/Grid";

function PhotoContributions() {
  const [photoContributions, setPhotoContributions] = useState([]);

  const loadContributions = async () => {
    try {
      const dbResponse = await getPhotoContributions();
      //   consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
      setPhotoContributions(dbResponse);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  function renderPhotos() {
    if (photoContributions.length !== 0) {
      return photoContributions.map((photo, index) => {
        return (
          <Grid item xs={3} key={`${photo.email}-${index}`}>
            <img src={photo.jpg} style={{ width: "100%" }} alt={photo.email} />
            <span style={{ fontSize: 9 }}>
              {photo.expression}
              <br />
              {photo.email}
              <br />
              {photo.createdDate}
              <br />
            </span>
          </Grid>
        );
      });
    }
  }

  useEffect(() => {
    loadContributions();
  }, []);

  return (
    <Grid container spacing={1}>
      {renderPhotos()}
    </Grid>
  );
}

export default PhotoContributions;
