import { consoleLog } from "../util/consoleLog";
import React, { useEffect, useState } from "react";
import { getSubscriptions } from "../util/apiConnect";
import Grid from "@mui/material/Grid";

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);

  const loadSubscriptions = async () => {
    try {
      const dbResponse = await getSubscriptions();
      //   consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
      setSubscriptions(dbResponse);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  function renderSubscriptions() {
    var subscriptionsRendered = [];
    if (subscriptions.length !== 0) {
      for (var i = 0; i < subscriptions.length; i++) {
        subscriptionsRendered.push(
          <React.Fragment
            key={subscriptions[i].email + subscriptions[i].createdDate}
          >
            <Grid item xs={6}>
              {subscriptions[i].email}
            </Grid>
            <Grid item xs={2}>
              {subscriptions[i].subscribeFor}
            </Grid>
            <Grid item xs={4}>
              {new Date(subscriptions[i].createdDate).toLocaleString()}
            </Grid>
          </React.Fragment>
        );
      }
      // consoleLog.log(subscriptionsRendered);
      return subscriptionsRendered;
    }
  }

  useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        Last 100 subscriptions:
      </Grid>
      {renderSubscriptions()}
    </Grid>
  );
}

export default Subscriptions;
