import trackingEvent from "../util/tracking";
import AdsenseFullscreen from "../util/adsense-code";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation, Trans } from "react-i18next";
import MetaDecorator from "../util/metaDecorator";

export default function Adsense() {
  const { t } = useTranslation();
  trackingEvent("other", "adTest");

  return (
    <>
      <MetaDecorator
        title={t("homepage.meta_title")}
        description={t("homepage.meta_description")}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minWidth: "340px", maxWidth: "700px" }}
      >
        <Grid item xs={12}>
          <AdsenseFullscreen />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{ typography: "body1", fontStyle: "italic" }}
            elevation={3}
            style={{ width: "100%" }}
          >
            <div style={{ padding: "20px" }}>
              <Typography sx={{ typography: "h6" }}>
                {t("homepage.intro1_headline")}
              </Typography>
              <Trans i18nKey="homepage.intro1_text1"></Trans>
              <ul>
                <li> {t("homepage.intro1_bullet1")}</li>
                <li> {t("homepage.intro1_bullet2")}</li>
                <li> {t("homepage.intro1_bullet3")}</li>
                <li> {t("homepage.intro1_bullet4")}</li>
              </ul>{" "}
              <p>{t("homepage.intro1_text2")}</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
