export const i18n_gameFinished_en = {
  meta_title: "Game finished - grimaces.net",
  meta_description:
    "You did great - an awesome set of weird faces that you collected with your friends. Let's play again!",
  textfield_label: "This is your look when you think of:",
  button_enlarge_photos: "Enlarge photos",
  button_contribute: "Stick your photo on the wall of fame...",
  button_restart: "Start a new game or open another game",
  button_talk_about: "Talk about grimaces.net & invite others...",
  button_subscribe: "Inform me about updates and new game modes",
  briefing_headline: "Talk about grimaces.net and play again!",
  briefing_text: `
    <p>That was fun, wasn't it? Please come back any time to play and invite more people round.</p>
    <p>Note that we will delete all photos in a day. If you want to keep your photo alive, 
    you can contribute it - maybe we'll put it on the wall of fame</p>
    <p>Happy grimacing!</p>
`,
  button_close_gallery: "Close gallery",
};

export const i18n_gameFinished_de = {
  meta_title: "Spiel beendet - grimaces.net",
  meta_description:
    "Du warst super! Da habt Ihr ein paar herrliche Grimassen gezogen - Du und Deine Freunde. Spiel doch gleich nochmal.",
  textfield_label: "So schaust Du wenn Du an diesen Grimsel denkst:",
  button_enlarge_photos: "Fotos vergrößern",
  button_contribute: "Versuche, in die Top 100 zu kommen",
  button_restart: "Starte ein neues Spiel oder öffne ein anderes Spiel",
  button_talk_about: "Empfehle grimaces.net Deinen Bekannten",
  button_subscribe: "Möchtest Du Infos über neue Spielvarianten erfahren?",
  briefing_headline: "Sprich über grimaces.net und spiel nochmal!",
  briefing_text: `
    <p>War das nicht ein toller Spaß? Ein herrlicher Unfug? Wir freuen uns, wenn 
    Du weiterspielst oder weitere Freunde einlädst. Viel Spaß weiterhin.</p>
    <p>Beachte, dass wir alle Photos einen Tag nach dem Spiel löschen. Wenn Du
    Deine Fotos sichern möchtest, kannst Du versuchen in die Top 100 zu kommen.</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
  button_close_gallery: "Fotogallerie schließen",
};
