import React, { useEffect, useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import AdsenseFullscreen from "../util/adsense-code";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import { useTranslation, Trans } from "react-i18next";
// import i18n from "i18next";
// import { consoleLog } from "../util/consoleLog";

export default function Adsense() {
  const { setStoryboardStep } = useContext(GameContext);
  const [adDone, setAdDone] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  var adSeconds = 0;
  if (process.env.REACT_APP_AD_TIME !== undefined) {
    adSeconds = process.env.REACT_APP_AD_TIME;
  }
  const [proceedIn, setProceedIn] = useState(adSeconds);

  function go2Gallery() {
    setStoryboardStep("gallery");
  }

  useEffect(() => {
    // consoleLog.log(
    //   `useEffect called: selfTimerIn: ${selfTimerIn}, timerInterval: ${timerInterval}`
    // );
    if (timerInterval === null && proceedIn > 0) {
      const handle = setInterval(() => {
        // consoleLog.log(`Interval tick: selfTimerIn: ${selfTimerIn}`);
        setProceedIn((current) => {
          return current - 1;
        });
      }, 1000);
      // consoleLog.log(`Interval created: ${handle}`);
      setTimerInterval(handle);
    } else if (proceedIn === 0) {
      // consoleLog.log(`Clear Interval Handle: ${timerInterval}`);
      setProceedIn(-1);
      clearInterval(timerInterval);
      setTimerInterval(null);
      setAdDone(true);
    }
    //eslint-disable-next-line
  }, [proceedIn, timerInterval]);

  useEffect(() => {
    if (adDone) {
      trackingEvent("conversion", "preGallery");
    }
  }, [adDone]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minWidth: "340px", maxWidth: "700px" }}
    >
      <Grid item xs={12}>
        Ad goes here...
        <AdsenseFullscreen />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!adDone}
          variant="contained"
          onClick={go2Gallery}
          style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        >
          {proceedIn === -1
            ? `Proceed to gallery`
            : `Proceed to gallery in ${proceedIn} seconds`}
        </Button>
      </Grid>
    </Grid>
  );
}
