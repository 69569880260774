import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function UserList(props) {
  const handleToggle = (value) => () => {
    const currentIndex = props.usersChecked.indexOf(value);
    const newChecked = [...props.usersChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.setUsersChecked(newChecked);
  };
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        shrink
        style={{
          backgroundColor: "#ffffff",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {props.listLabel}
      </InputLabel>
      <Box
        component="span"
        sx={{ border: "1px solid grey", borderRadius: "5px" }}
      >
        <Grid container rowSpacing={0} style={{ padding: "3px" }}>
          <Grid item xs={12}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                maxHeight: `${props.maxHeight}px`,
                overflow: "auto",
              }}
            >
              {props.friendsList.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem key={value} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense={true}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={props.usersChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Box>
    </FormControl>
  );
}
export default UserList;
