export const i18n_subscribe_en = {
  meta_title: "Subscribe - grimaces.net",
  meta_description:
    "We are happy to keep you informed about updates on grimaces.net! New features, specials or beta versions - subscribe to the news...",
  button_subscribe:
    "I accept the terms and want to subscribe to news on grimaces.net",
  saving: "saving...",
  error_subscribe: "Error saving subscription. Please try again later",
  button_back: "Back to the game",
  briefing_headline: "Subscribe to news on grimaces.net",
  briefing_text: `
    <p>Subscribe to stay informed about new features, game modes, specials 
    and sneak previews / beta phases. We don't sell your email or use it 
    for other purposes than to inform you about things going on on grimaces.net.</p>
    <p>Every mail we send you will include a link to easily unsubscribe. To read the 
    details of our terms of use and data protection, please see in the menu in the 
    upper right corner.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_subscribe_de = {
  meta_title: "Informiert bleiben - grimaces.net",
  meta_description:
    "Gerne halten wir Dich auf dem Laufenden über Aktionen, weitere Spielmodi und andere Neuerungen auf grimaces.net. Melde Dich einfach an....",
  button_subscribe: "Ich möchte Infos über grimaces.net erhalten",
  saving: "speichern...",
  error_subscribe:
    "Da hat was nicht funktioniert. Bitte versuche es später wieder",
  button_back: "Zurück zum Spiel",
  briefing_headline: "Bleibe auf dem Laufenden",
  briefing_text: `
    <p>Gerne halten wir Dich auf dem Laufenden über Aktionen, weitere Spielmodi und 
    andere Neuerungen auf grimaces.net - melde Dich einfach an.</p>
    <p>Jede Mail wird einen Link enthalten, um Dich wieder abzumelden, wenn Du es 
    Dir anders überlegst. Die detaillierten Nutzungsbedingungen findest Du rechts 
    oben in der Navigation.</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
