export const i18n_participant_needs_signup_en = {
  meta_title: "Signup to see all grimaces - grimaces.net",
  meta_description:
    "Grimaces.net is about making weird faces with your friends. Before you can see your frieds grimace we need to make sure you are the right person. Please sign up or log in to see the game status and result.",
  button_proceed: "Sign up or login to see all grimaces",
  briefing_headline: "Sign up to see all grimaces!",
  briefing_text: `
    <p>You already contributed your grimace to this game - excellent move!</p>
    <p>Before you can see your frieds grimace we want to doulbe check you are the right person.
    Please sign up or log in to see the game status and result. We will send a 
    confirmation mail to your account to protect your privacy.</p>
    <p>Once you are logged in, you can also re-take your shot.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_participant_needs_signup_de = {
  meta_title: "Anmelden, um alle Grimassen zu sehen - grimaces.net",
  meta_description:
    "Bevor Du die Grimassen Deiner Freunde sehen kannst, müssen wir sicherstellen, dass Du die richtige Person bist. Bitte registriere Dich oder melde Dich an.",
  button_proceed: "Anmelden oder Registrieren, um alle Grimassen zu sehen",
  briefing_headline: "Melde Dich an um alle Grimassen zu sehen!",
  briefing_text: `
    <p>Deine Grimasse hast Du schon abgegeben - ein ausgezeichneter Anfang!</p>
    <p>Bevor Du die Grimassen Deiner Freunde sehen kannst, müssen wir sicherstellen, dass Du 
    die richtige Person bist. Bitte registriere Dich oder melde Dich an.
    Wir werden Dir eine Bestätigungsmail senden, um sicherzustellen, dass Deine Privatsphäre gewahrt wird</p>
    <p>Sobald Du Dich angemeldet hast, kannst Du Dein Foto auch erneut aufnehmen</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
