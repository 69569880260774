import { Button, Grid, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { adminAddUser } from "../util/apiConnect";
import Result from "./components/Result";

function AddUser() {
  const Result_INACTIVE = "INACTIVE";
  const Result_SUCCESS = "SUCCESS";
  const Result_ERROR = "ERROR";

  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [godMode, setGodMode] = useState(false);
  const [resultState, setResultState] = useState(Result_INACTIVE);
  const [resultMessage, setResultMessage] = useState(null);

  function setResult(state, message) {
    setResultState(state);
    setResultMessage(message);
  }

  function updateEmail(e) {
    setEmail(e.target.value);
  }

  function updatePin(e) {
    setPin(e.target.value);
  }

  function updateGodMode(e) {
    setGodMode(e.target.checked);
  }

  function addUser() {
    adminAddUser(email, pin, godMode)
      .then((result) => {
        console.log(result);
        if (result.data.errorCode) {
          const error = result.data;
          switch (error.errorCode) {
            case 1:
              setResult(
                Result_ERROR,
                `ERROR: code: ${error.errorCode} -> ${error.name}: ${error.message}`
              );
              break;
            default:
              setResult(Result_ERROR, `Unknown error code: ${error.errorCode}`);
          }
        } else {
          setResult(Result_SUCCESS, "SUCCESS!");
        }
      })
      .catch((error) => {
        console.error(error);
        setResult(Result_ERROR, "ERROR!");
      });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1>Add a new user</h1>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="newUser"
          label="add email"
          variant="outlined"
          style={{ width: "100%", marginTop: "10px" }}
          value={email}
          onChange={updateEmail}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="pin"
          label="enter PIN"
          variant="outlined"
          style={{ width: "100%", marginTop: "10px" }}
          value={pin}
          onChange={updatePin}
        />
      </Grid>
      <Grid item xs={3}>
        <Switch label="god mode" onChange={updateGodMode}></Switch>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          style={{ width: "100%", height: "85%", marginTop: "10px" }}
          onClick={addUser}
        >
          add user
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Result state={resultState} message={resultMessage} />
      </Grid>
    </Grid>
  );
}

export default AddUser;
