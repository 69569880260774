import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import BriefingI18n from "../support/briefingI18n";
import { CREDITS } from "../const/assets";
import { useTranslation } from "react-i18next";
import WallOfFame from "../support/wallOfFame";

function Imprint(props) {
  const { determineStoryboardStep } = useContext(GameContext);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title="Imprint - Grimaces"
        description="Grimaces.net is about making weird faces with your friends. Read here who we are at grimaces.net"
      />
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("generic.button_back")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <BriefingI18n briefing="imprint" values={{ credits: CREDITS }} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("generic.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default Imprint;
