export const i18n_gameStatus_en = {
  meta_title: "Game status - grimaces.net",
  meta_description:
    "Your friends are informed and asked to contribute their grimaces - the clock is ticking. What a gorgeous nonsense!",
  textfield_label: "This is your look when you think of:",
  button_shoot_again: "Take a new shot - I can do better",
  button_restart: "Open or start another game",
  game_duration_label: "Game time remaining...",
  duration_hours: "hours",
  duration_minutes: "minutes",
  duration_seconds: "seconds",
  game_finished: "Game finished",
  button_stop_early: "Stop the game early",
  participant_status_host: " (game host)",
  participant_status_waiting_for: "Waiting for",
  participant_status_to_contribute: "to contribute...",
  participant_status_noShow: "did not participate",
  participant_status_rain_check: "took a rain check",
  briefing_headline: "How long do I have to wait?",
  briefing_text: `
    <p>As the games' host you will see thumbnails of every grimace shortly after your 
    friends contributed. All other players have to wait till the end of the game.</p>
    <p>We will inform you the minute everyone contributed their photo. All participants will then see all grimaces. 
    If you want to cut it short you can stop the game anytime ('Stop the game early' below).
    But you will never know what you have missed...</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_gameStatus_de = {
  meta_title: "Spielstatus - grimaces.net",
  meta_description:
    "Deine Freunde sind informiert und eingeladen, ihre Grimassen beizusteuern. Die Uhr tickt. Oh wie spannend...",
  textfield_label: "So schaust Du wenn Du an diesen Grimsel denkst:",
  button_shoot_again: "Grimasse neu aufnehmen - das kann ich besser",
  button_restart: "Anderes Spiel starten oder öffnen",
  game_duration_label: "Verbleibende Spielzeit...",
  duration_hours: "Stunden",
  duration_minutes: "Minuten",
  duration_seconds: "Sekunden", // TODO i18next plurals
  game_finished: "Spiel beendet",
  button_stop_early: "Spiel vorzeitig beenden",
  participant_status_host: " (Gastgeber)",
  participant_status_waiting_for: "Warten auf Teilname von",
  participant_status_to_contribute: "...",
  participant_status_noShow: "hat nicht mitgespielt",
  participant_status_rain_check: "entschuldigt sich dieses mal",
  briefing_headline: "Wie lange muss ich jetzt warten?",
  briefing_text: `
    <p>Als Ausrichter des Spiels siehst Du eine Vorschau der Grimassen der anderen Teilnehmer 
    schon vor Ablauf des Spiels. Alle anderen müssen sich bis Spielende gedulden.</p>
    <p>Sobald alle Teilnehmer ihre Grimasse beigesteuert haben, informieren wir alle.
    Wenn Du es nicht mehr erwarten kannst, kannst Du das Spiel unten vorzeitig beenden. 
    Aber wer weiß, was Du dann verpasst...</p>
    <p>Happy grimacing!</p>
`,
};
