// import { consoleLog } from "../util/consoleLog";
import React, { useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import BriefingI18n from "../support/briefingI18n";
import GrimaceBanner from "../support/grimaceBanner";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function Login(props) {
  const { setStoryboardStep, logInAs, setLogInAs, loggedIn, logOut } =
    useContext(GameContext);
  const [email, setEmail] = useState(logInAs);
  const { t } = useTranslation();

  function handleChange(e) {
    // consoleLog.log(e.target.value);
    setEmail(e.target.value);
  }

  function logInOut() {
    if (loggedIn === false) {
      setLogInAs(email.trim());
      setStoryboardStep("loginPwd");
      trackingEvent("account", "login_username");
    } else {
      setLogInAs("");
      setEmail("");
      logOut(); // event tracked in gameContext.js
    }
  }

  function signUpOrPlayGame() {
    if (loggedIn) {
      setStoryboardStep("participants");
    } else {
      setLogInAs(email);
      setStoryboardStep("signUp");
      trackingEvent("account", "signup_username");
    }
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("login.meta_title")}
        description={t("login.meta_description")}
      />
      <Grid item xs={12}>
        <GrimaceBanner />
      </Grid>
      <Grid item xs={12}>
        <Textfield
          id="email"
          value={email}
          autoFocus
          label={t("login.textfield_label")}
          variant="outlined"
          style={{ width: "100%", marginTop: "15px" }}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === "Enter" && email) {
              logInOut();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!email}
          variant={!loggedIn ? "contained" : "outlined"}
          onClick={logInOut}
          style={{ width: "100%" }}
        >
          {!loggedIn
            ? t("login.button_action_in")
            : t("login.button_action_out")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!email}
          variant={loggedIn ? "contained" : "outlined"}
          onClick={signUpOrPlayGame}
          style={{ width: "100%" }}
        >
          {!loggedIn
            ? t("login.button_next_loggedOut")
            : t("login.button_next_loggedIn")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="login" />
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={15} />
      </Grid>
    </Grid>
  );
}

export default Login;
