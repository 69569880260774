import { useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

function PhotoGallery() {
  const { setStoryboardStep, photos } = useContext(GameContext);
  const { t } = useTranslation();

  // TODO Mal beobachten: Carousel wirft ne Warning: Using UNSAFE_componentWillReceiveProps in strict mode.

  function closeGallery() {
    setStoryboardStep("finished");
    trackingEvent("gallery", "closeGallery");
  }

  function renderCarouselItems() {
    return photos.map((photo, index) => {
      // consoleLog.log(`photo.user ${photo.user}`);
      // consoleLog.log(`photo.jpg ${photo.jpg}`);
      return (
        <div key={index}>
          <img src={photo.jpg} alt={`Grimace of ${photo.user}`} />
        </div>
      );
    });
  }
  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            closeGallery();
          }}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {t("gameFinished.button_close_gallery")}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        className="carousel-wrapper"
        style={{ maxWidth: "450px" }}
      >
        <Carousel
          showStatus={false}
          swipeable={true}
          emulateTouch={true}
          useKeyboardArrows={true}
          // selectedItem=
          onSwipeStart={() => {
            trackingEvent("gallery", "swipeStart");
          }}
          onClickThumb={() => {
            trackingEvent("gallery", "clickThumb");
          }}
          onChange={() => {
            trackingEvent("gallery", "changeItem");
          }}
        >
          {renderCarouselItems()}
        </Carousel>
      </Grid>
    </Grid>
  );
}
export default PhotoGallery;
