export const i18n_startGame_en = {
  meta_title: "Start your game - grimaces.net",
  meta_description:
    "Time to start the game and invite your friends to contribute their grimaces. This will be awesome!",
  textfield_label: "This is your look when you think of:",
  select_duration_label: "Pick game duration",
  duration_60: "One minute",
  duration_180: "Three minutes",
  duration_600: "10 minutes",
  duration_1800: "30 minutes",
  duration_3600: "One hour",
  duration_10800: "Three hours",
  duration_21600: "Six hours",
  duration_86400: "24 hours",
  duration_259200: "Three days",
  button_start_game: "Start game & send invitations",
  button_shoot_again: "Take a new shot - I can do better",
  briefing_headline: "What happens next?",
  briefing_text: `
    <p>When you start the game we send an eMail to your friends and ask 
    them to join your game. They will know the expression but will not see your photo. 
    The Participants also see who else is joining the game - after all you want 
    to know who you share your grimace with.</p>
    <p>When all your friends send a shot of their grimace or the time is up, we will 
    inform you about the results. If you want you can always cut it short, stop the 
    game and disclose all contributions.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_startGame_de = {
  meta_title: "Starte Dein Spiel - grimaces.net",
  meta_description:
    "Zeit, Dein Spiel zu starten und Dein Freunde einzuladen, ihre Grimassen zu fotografieren. Das wird großartig!",
  textfield_label: "So schaust Du wenn Du an diesen Grimsel denkst:",
  select_duration_label: "Wähle die Spieldauer",
  duration_60: "Eine Minute",
  duration_180: "Drei Minuten",
  duration_600: "10 Minuten",
  duration_1800: "30 Minuten",
  duration_3600: "Eine Stunde",
  duration_10800: "Drei Stunden",
  duration_21600: "Sechs Stunden",
  duration_86400: "24 Stunden",
  duration_259200: "Drei Tage",
  button_start_game: "Spiel starten & Einladungen versenden",
  button_shoot_again: "Grimasse neu aufnehmen - das kann ich besser",
  briefing_headline: "Was passiert als nächstes?",
  briefing_text: `
    <p>Wenn Du das Spiel startest, senden wir eine eMail an Deine Freunde und laden Sie 
    ein, mitzuspielen. Die Teilnehmer kennen dann Deinen Grimsel, sehen aber noch nicht Deine Grimasse.
    Jeder sieht außerdem die eMail-Adressen aller anderen Teilnehmer - man will ja wissen, 
    mit wem man seine Grimasse teilt</p>
    <p>Wenn alle Teilnehmer ihr Foto geteilt haben oder abgelehnt haben oder die 
    Spielzeit abgelaufen ist, sind alle Grimassen sichtbar. Als Ausrichter des Spiels
    kannst Du es auch immer vorzeitig beenden - aber wer weiß, was Du dann verpasst...
    </p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
