import { consoleLog } from "./consoleLog";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
consoleLog.log({ baseURL: axios.defaults.baseURL });

// const useDevProxy = true;
// const useDevProxy = false;

export const getServerTime = async () => {
  try {
    const response = await axios.get(`/serverTime`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const getUserByEmail = async (user) => {
  // consoleLog.log(`user: ${JSON.stringify(user)}`);
  try {
    const response = await axios.post("/user", user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writeUserToBlackList = async (email) => {
  // consoleLog.log(`email: ${email}`);
  try {
    const response = await axios.post("/addUserToBlackList", email, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // consoleLog.log(response.data);
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writeUserSubscription = async (subscription) => {
  // consoleLog.log(`email: ${email}`);
  try {
    await axios.post("/addUserSubscription", subscription, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writeSetParticipantDeclined = async (gameId, email) => {
  consoleLog.log(`email: ${email}`);
  consoleLog.log(`gameId: ${gameId}`);
  try {
    await axios.put(
      "/userDeclined",
      { gameId, email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const getFriendsListByEmail = async (email) => {
  try {
    // consoleLog.log(`/friendsList/:${email}`);
    const response = await axios.get(`/friendsList/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};
export const getLatestGrimace = async (email) => {
  try {
    // consoleLog.log(`/latestGrimace/:${email}`);
    const response = await axios.get(`/latestGrimace/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const updateFriendsList = async (email, friendsList) => {
  try {
    // consoleLog.log(`/friendsList/:${email}`);
    // consoleLog.log(`friendsList ${friendsList}`);
    const response = await axios.put(
      `/updateFriendsList/${email}`,
      { friendsList },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const fetchEmailsFromPrevGames = async (email) => {
  // consoleLog.log(`fetchEmailsFromPrevGames for :${email}`);
  try {
    const response = await axios.get(`/getEmailsFromPrevGames/${email}`, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writeGame = async (game, sendRealInvitations) => {
  try {
    await axios.post(
      "/addGame",
      { game, sendRealInvitations },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const getGameListByEmail = async (email) => {
  try {
    // consoleLog.log(`/gameList/${email}`);
    const response = await axios.get(`/gameList/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // consoleLog.log(`gameList fetched: ${response.data}`);
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const getGameById = async (id) => {
  try {
    // consoleLog.log(`/game/${id}`);
    const response = await axios.get(`/game/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writePhoto = async (gameId, photo) => {
  //consoleLog.log(`gameId: ${JSON.stringify(gameId)}`);
  //consoleLog.log(`photo: ${JSON.stringify(photo)}`);
  try {
    const response = await axios.put(`/updatePhoto/${gameId}`, photo, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writePhotoContribution = async (gameId, email) => {
  consoleLog.log(`gameId in apiConnect: ${gameId}`);
  consoleLog.log(`email in apiConnect: ${email}`);
  try {
    await axios.post(
      "/contributePhoto",
      { gameId, email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const updateGameEndTime = async (gameId, gameEndTime) => {
  consoleLog.log(`game: ${JSON.stringify(gameEndTime)}`);
  try {
    await axios.put(`/updateGameEndTime/${gameId}`, gameEndTime, {
      headers: { "Content-Type": "application/json" },
    });
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

// ==================================================================

export const getPhotoContributions = async () => {
  try {
    const response = await axios.get(`/photoContributions`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const getSubscriptions = async () => {
  try {
    const response = await axios.get(`/subscriptions`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const deleteFakeGames = async () => {
  try {
    const response = await axios.delete(`/deleteFakeGames`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const removeAgedPhotos = async () => {
  try {
    const response = await axios.put(`/removeAgedPhotos`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const writeGameEnd = async () => {
  try {
    const response = await axios.put(`/writeGameEnd`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};

export const adminAddUser = async (email, pin, godMode) => {
  // api/src/models/grimacesModel.js
  const user = {
    email,
    pin,
    godMode,
  };

  return axios.post("/addUser", user);
};

export const getJournal = async () => {
  try {
    const response = await axios.get(`/journal`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    consoleLog.log(err);
    throw err;
  }
};
