export const i18n_generic_en = {
  happy_grimacing: "Happy grimacing!",
  cookie_consent_message:
    "This website uses cookies to work and to enhance the user experience.",
  cookie_consent_button: "Cookies - yummy! I accept them all...",
  prompt_message:
    "To navigate through the game, use the buttons instead of browser-back. Are you sure you want to leave grimaces.net?",
  menu_reload: "Refresh game",
  menu_login: "Login / Logout",
  menu_pick_game: "Pick or create game",
  menu_participants: "Pick participants",
  menu_expression: "Enter expression",
  menu_take_photo: "Take your photo",
  menu_start_game: "Start game",
  menu_status: "Game status",
  menu_finished: "Game result",
  menu_gallery: "Photo gallery",
  menu_contribute: "Contribute photo",
  menu_wall_of_fame: "Wall of fame",
  menu_subscribe: "Subscribe",
  menu_terms: "Terms of use",
  menu_gdpr: "Data protection",
  menu_imprint: "Imprint",
  button_back: "Back to game",
  briefing_headline: "Terms of use",
  briefing_text: `
  <p>In a nutshell our terms of use are the following: We provide you a pleasant nonsense
  on grimaces.net. It is free of charge but we might present some advertising to you.
  We don't guarantee any scope or quality of service and can not be held liable for 
  side effect of your consumption of our service. After all it is free and harmless.
  </p><p>
  We collect photos and share of you and share them with your friend. You need to make sure 
  only to share photos of yourself - you need to hold the rights to share those photos and 
  must not infringe anbody elses right by sharing or break the law or bon ton. 
  After all it is about having some fun.
  </p><p>
  For the avoidance of doubt you find the details of our terms of use and data protection 
  in the menu in the upper right corner. By using our service you accept those terms of use.
  </p><p>
  Happy grimacing!</p>
  `,
};

export const i18n_generic_de = {
  happy_grimacing: "Fröhliches Grimassen schneiden!",
  cookie_consent_message:
    "Diese Website nutzt Cookies (Kekse), um zu funktionieren und um besser zu werden.",
  cookie_consent_button: "Kekse - lecker! Her mit allen Cookies...",
  prompt_message:
    "Um im Spiel zu navigieren, nutze die Buttons oder die Navigation oben rechts statt der Browser-Pfeile. Möchtest Du grimaces.net jetzt verlassen?",
  menu_reload: "Spielstand aktualisieren",
  menu_login: "Anmelden / Abmelden",
  menu_pick_game: "Spiel wählen oder erstellen",
  menu_participants: "Mitspieler wählen",
  menu_expression: "Spruch festlegen",
  menu_take_photo: "Grimasse aufnehmen",
  menu_start_game: "Spiel starten",
  menu_status: "Spielstatus",
  menu_finished: "Spielergebnis",
  menu_gallery: "Fotogalerie",
  menu_contribute: "Foto freigeben",
  menu_wall_of_fame: "Top 100 Grimassen",
  menu_subscribe: "Newsletter abonnieren",
  menu_terms: "Nutzungsbedingungen",
  menu_gdpr: "Datenschutz",
  menu_imprint: "Impressum",
  button_back: "Zurück zum Spiel",
  briefing_headline: "Nutzungsbedingungen",
  briefing_text: `
  <p>In der Kurzfassung sind unsere Nutzungsbedingungen die folgenden: Wir bieten Euch auf 
  grimaces.net einen kurzweiligen, wohltuenden Unfug. Das Spiel kostet nichts, aber wir zeigen 
  Euch hin und wieder etwas Werbung. 
  Wir garantieren keinen Erreichbarkeit oder Servicequalität und haften nicht für irgendwelche 
  Folgen oder Nebenwirkungen des Grimassen schneidens oder Eurer Beschäftigung mit dem Spiel. 
  </p><p>
  In dem Spiel sammeln wir Fotos von Dir und teile diese mit Deinen Freunden. Du stellst sicher,
  dass Du nur Fotos aufnimmst auf denen Dein Gesicht zu sehen ist. Du versicherst, dass Du die 
  notwendigen Rechte an dem Foto hast und keine Rechte Dritter verletzt. Außerdem versicherst Du, 
  dass Du mit den Fotos nicht gegen Gesetze oder den guten Ton verstößst. Schließlich geht es 
  um Grimassen und einen harmlosen Spaß.
  </p><p>
  Zur Vermeidung von Missverständnissen findest Du die detaillierten Nutzungsbedingungen und die
  Datenschutzbedingungen im Menu oben rechts. Du bestätigst, mit den kompletten Nutzungsbedingungen 
  einverstanden zu sein, wenn sie durch Klich auf den Button unten akzeptierst.
  </p><p>
  <strong>Und dann kann es ja losgehen: Fröhliches Grimassen schneiden!</strong></p>
  `,
};
