import React, { useEffect, useState } from "react";
import trackingEvent from "../util/tracking";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CookieConsent from "react-cookie-consent";
import MetaDecorator from "../util/metaDecorator";
import grimacesLogoEn from "../img/grimace_logo_en.svg";
import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import WallOfFame from "../support/wallOfFame";
import { CREDITS } from "../const/assets";
import { writeUserSubscription } from "../util/apiConnect";
import { useTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import flagOfTheUnitedKingdom from "../img/flag_of_the_united_kingdom.svg";
import flagOfGermany from "../img/flag_of_germany.svg";
// import { consoleLog } from "../util/consoleLog";

export default function Homepage() {
  const [subscribed, setSubscribed] = useState(false);
  const [shuffeledGrimaces, setShuffeledGrimaces] = useState([]);
  const [savingSubscribe, setSavingSubscribe] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const imgPath = "/img/";
  useEffect(() => {
    // consoleLog.log(i18n.language);
    var currentLanguage = "en";
    if (i18n.language.indexOf("de") === -1) {
      currentLanguage = "en";
    } else {
      currentLanguage = "de";
    }
    const grimaces = [
      `usman-yousaf-8dvyPDYa35Q-unsplash_with_text_${currentLanguage}.png`,
      `ilya-mirnyy-y-WSiwQgJb8-unsplash_with_text_${currentLanguage}.png`,
      `artem-beliaikin-XO25cX2_0iE-unsplash__with_text_${currentLanguage}.png`,
      `henrikke-due-pVsi_lmyvL8-unsplash_with_text_${currentLanguage}.png`,
      `danie-franco-A6O7pgc7vHg-unsplash_with_text_${currentLanguage}.png`,
    ];
    setShuffeledGrimaces(grimaces.sort((a, b) => 0.5 - Math.random()));
    //eslint-disable-next-line
  }, [i18n.language]);

  // useEffect(() => {
  //   setShuffeledGrimaces(grimaces.sort((a, b) => 0.5 - Math.random()));
  //   //eslint-disable-next-line
  // }, []);

  function handleChange(e) {
    setEmail(e.target.value);
  }

  const subscribe = async () => {
    if (email === "") {
      alert(t("homepage.subscribe_textfield_label"));
      return false;
    }
    trackingEvent("other", "subscribe", "", "beta");
    try {
      setSavingSubscribe("saving");
      await writeUserSubscription({
        email: email,
        subscribeFor: "beta",
      });
      setSavingSubscribe("saved");
      setSubscribed(true);
      // consoleLog.log(`email: ${email}`);
    } catch (err) {
      trackingEvent("nonFatal", "exception", "subscribeOnHompage", err);
      setSavingSubscribe("saveError");
    }
  };

  function labelSubscribe() {
    if (savingSubscribe === "saved")
      return t("homepage.subscribe_button_success");
    else if (savingSubscribe === "saveError")
      return t("homepage.subscribe_button_error");
    else return t("homepage.subscribe_button_signup");
  }

  function setLanguage(language) {
    i18n.changeLanguage(language);
    trackingEvent("other", "changeLanguage", " ", language);
  }

  return (
    <>
      <MetaDecorator
        title={t("homepage.meta_title")}
        description={t("homepage.meta_description")}
      />
      <div>
        <CookieConsent
          location="bottom"
          overlay={true}
          expires={1}
          buttonText={t("generic.cookie_consent_button")}
          style={{ background: "#4196f6" }}
          buttonStyle={{
            color: "#000000",
            background: "#ffd700",
            fontSize: "13px",
          }}
        >
          <Typography variant="body2">
            {t("generic.cookie_consent_message")}
          </Typography>
        </CookieConsent>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minWidth: "340px", maxWidth: "700px" }}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ width: "100%" }}>
          <img
            src={grimacesLogoEn}
            style={{
              width: "90%",
              maxHeight: 45,
              marginTop: "10px",
              marginBottom: "10px",
            }}
            alt="grimaces.net - a pleasant nonsene - a fun game"
          />
        </Grid>
        <Grid item xs={1}>
          <span onClick={() => setLanguage("en")} style={{ cursor: "pointer" }}>
            <img
              src={flagOfTheUnitedKingdom}
              style={{ width: 25 }}
              alt="English"
            />
          </span>
          <br />
          <span onClick={() => setLanguage("de")} style={{ cursor: "pointer" }}>
            <img src={flagOfGermany} style={{ width: 25 }} alt="German" />
          </span>
        </Grid>
        <Grid item xs={12}>
          <img
            src={imgPath + shuffeledGrimaces[0]}
            alt={t("homepage.img_alt_default")}
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "10px",
              borderBottom: "1px solid #a0a0a0",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{ typography: "body1", fontStyle: "italic" }}
            elevation={3}
            style={{ width: "100%" }}
          >
            <div style={{ padding: "20px" }}>
              <Typography sx={{ typography: "h6" }}>
                {t("homepage.intro1_headline")}
              </Typography>
              <Trans i18nKey="homepage.intro1_text1"></Trans>
              <ul>
                <li> {t("homepage.intro1_bullet1")}</li>
                <li> {t("homepage.intro1_bullet2")}</li>
                <li> {t("homepage.intro1_bullet3")}</li>
                <li> {t("homepage.intro1_bullet4")}</li>
              </ul>{" "}
              <p>{t("homepage.intro1_text2")}</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <img
            src={imgPath + shuffeledGrimaces[1]}
            alt={t("homepage.img_alt_default")}
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "10px",
              borderBottom: "1px solid #a0a0a0",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{ typography: "body1", fontStyle: "italic" }}
            elevation={3}
            style={{ width: "100%" }}
          >
            <div style={{ padding: "20px" }}>
              <Typography sx={{ typography: "h6" }}>
                {t("homepage.intro2_headline")}
              </Typography>
              <p>{t("homepage.intro2_text")} </p>
              <p>{t("generic.happy_grimacing")}</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Textfield
            id="email"
            value={email}
            disabled={subscribed}
            autoFocus
            label={t("homepage.subscribe_textfield_label")}
            variant="outlined"
            style={{
              width: "100%",
              marginTop: "15px",
              backgroundColor: "#d4ebff",
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={subscribed}
            variant="contained"
            onClick={subscribe}
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            {labelSubscribe()}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{ typography: "body1", fontStyle: "italic" }}
            elevation={3}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <div style={{ padding: "20px" }}>
              <Typography sx={{ typography: "h6" }}>
                {t("homepage.wall_of_fame_headline")}
              </Typography>
              <p>{t("homepage.wall_of_fame_text")}</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <WallOfFame itemCount={15} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ typography: "body2" }}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #a0a0a0",
            }}
          >
            {t("homepage.imprint")}
            <br />
            <br />
            <span style={{ fontSize: "8pt" }}>
              actyvyst GmbH. Gerresheimer Straße 153, 40233 Düsseldorf.
              Geschäftsführer: Thomas Görldt, Ralf Siewert Handelsregister beim
              Amtsgericht Düsseldorf Registernummer: HRB 86132 Umsatzsteuer-ID
              gemäß §27 a Umsatzsteuergesetz: DE324741447. Idee und Umsetzung:
              Ulrich Klostermann. Inhaltliche Verantwortung: Ralf Siewert,
              Dorotheenstraße 39, 53111 Bonn
              <br />
              Credit: Photos by {CREDITS} on UNSPLASH.COM
            </span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
