export const i18n_wall_of_fame_en = {
  meta_title: "The wall of fame - grimaces.net",
  meta_description:
    "Grimaces.net is about making weird faces with your friends. This is a compilation of the best grimaces",
  button_back: "Continue",
  briefing_headline: "The wall of fame",
  briefing_text: `
    <p>Here's our top 100 collection of grimaces. Some of them we found on unsplash.com - 
    please see the imprint for credits. Others were contributed by you through this game.</p>
    <p>If you want to see your grimace up on the wall, you can contribute your photo once the 
    game is finished. Note that we review all contributions before showing them on the wall of fame.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_wall_of_fame_de = {
  meta_title: "Die Top 100 - grimaces.net",
  meta_description:
    "Hier siehst Du eine Zusammenstellung der besten Grimassen - Lass Dich inspirieren und viel Spaß auf grimaces.net",
  button_back: "Zurück",
  briefing_headline: "Die Top 100 Grimassen",
  briefing_text: `
    <p>Hier sind sie - unsere Top 100 Grimassen. Einige davon haben wir auf unsplash.com 
    gefunden - die Fotografen haben wir im Impressum gelistet. Andere Bilder sind von Euch 
    Spielern auf grimaces.net</p>
    <p>Wenn Du es in die Top 100 schaffen möchtest, kannst Du Dein Foto nach jedem Spiel teilen. 
    Wir schauen alle freigegebenen Fotos durch und die besten nehmen wir in die Top 100 auf. 
    Viel Erfolg und noch mehr Spaß!</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
