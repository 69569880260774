export const i18n_participant_consent_en = {
  meta_title: "Join a game - grimaces.net",
  meta_description:
    "You have been invited by a friend and are about to join a fun game. This is about making funny faces and share among friends.",
  loading: "loading...",
  loaded: "loaded",
  error_loading: "Cannot load game. Unclear which game or who is invited.",
  error_no_participant: "User is not invited or game does not exist",
  error_no_game: "Game does not exist or user is not invited",
  saving: "saving",
  saved: "saved",
  confirm_rain_check:
    "Looking forward to see you another time. Do you really want to take the rain check now?",
  error_rain_check: "Error saving rain check. Please try again later",
  button_rain_check: "I do not want to join now. Maybe another time...",
  confirm_opt_out:
    "If you change your mind and want to join again later, just sign up to grimaces.net. Do you really want to opt out from grimaces.net now?",
  error_opt_out: "Error saving opt out. Please try again later",
  button_opt_out: "I will never join this fun game. Don't contact me again!",
  textfield_confirm_email: "Confirm your email-Adress",
  button_confirm_email: "I confirm my eMail address and the terms of use",
  brief_headline: "grimaces.net sounds like fun! What is it about?",
  brief_text: `<p>
  You have been invited to this fun game by <strong>{{gameHost}}</strong>. The
  host came up with the expression "<strong>{{gameExpression}}</strong>" and
  took a selfie of his face matching it. He than invited the the
  following friends to contribute a photo as well:
  <strong>{{participants}}</strong>
</p>
<p>
  The game is still running for <strong>{{remainingTime}}</strong>. As soon as
  everyone contributed or when the time is up, the photos are shared
  among all participants. This will be fun!
</p>
<p>
  If you want to contribute your grimace, just confirm your eMail
  address and accept the terms of use below - the photos are only
  used for the purpose of the game, only shared with the people
  listed above and are deleted after game.
</p>
<p>
  If you don't want to join this time, please let the others know
  and take the rain check. If you never want to here about grimaces
  again, you can opt out at the bottom of this page.
</p>
<p>Happy grimacing!</p>
 `,
};

export const i18n_participant_consent_de = {
  meta_title: "Einladung zum Spiel - grimaces.net",
  meta_description:
    "Du bist von einem Freund zum Spiel auf grimaces.net eingeladen worden. Es geht darum, Grimassen zu schneiden und diese mit Deinen Freunden zu teilen.",
  loading: "lade...",
  loaded: "geladen",
  error_loading:
    "Fehler beim Laden des Spiels. Unklar wer oder was zu laden ist",
  error_no_participant: "Nutzer nicht eingeladen oder Spiel nicht vorhanden",
  error_no_game: "Spiel nicht vorhanden oder Nutzer nicht eingeladen",
  saving: "speichern",
  saved: "gespeichert",
  confirm_rain_check:
    "Wir freuen uns schon auf's nächste Mal. Magst Du wirklich absagen?",
  error_rain_check:
    "Fehler beim Speichern der Absage. Bitte versuche es später wieder",
  button_rain_check:
    "Ich möchte nicht mitspielen. Vielleicht ein anderes mal...",
  confirm_opt_out:
    "Wenn Du Dich umentscheidest, kannst Du Dich einfach bei grimaces.net anmelden. Möchtest Du jetzt wirklich dauerhaft aussteigen?",
  error_opt_out:
    "Fehler beim Speichern der Absage. Bitte versuche es später wieder",
  button_opt_out: "So einen Quatsch mache ich niemals. Lasst mich in Ruhe",
  textfield_confirm_email: "Bitte bestätige Deine eMail Adresse",
  button_confirm_email:
    "Ich bestätige meine eMail Adresse und akzeptiere die Nutzungsbedingungen",
  brief_headline: "grimaces.net klingt lustig! Worum geht es?",
  brief_text: `<p>
    Du bist von <strong>{{gameHost}}</strong> eingeladen worden zu diesem kurzweiligen Spiel.
    Der Gastgeber hat sich den Spruch "<strong>{{gameExpression}}</strong>" ausgedacht, das 
    passende Gesicht dazu gemacht und ein Selfie aufgenommen. 
    Dann hat er die folgenden Freunde eingeladen, das gleiche zu tun: 
    <strong>{{participants}}</strong>
  </p>
  <p>
    Das Spiel läuft noch <strong>{{remainingTime}}</strong>. Sobald alle Ihr Foto 
    geteilt haben oder die Zeit abgelaufen ist, werden die Fotos mit den 
    Spielteilnehmern geteilt. Das ist immer ein großer Spaß!
  </p>
  <p>
    Wenn Du das ausprobieren möchtest, bestätige Deine eMail Adresse und 
    die Nutzungsbedingungen unten. Die Fotos speichern wir nur für den 
    Zweck dieses Spiels und teilen sie auch nur mit den Spielteilnehmern. 
    Nach dem Spiel löschen wir alle Fotos wieder.
  </p>
  <p>
    Wenn Du keine Lust oder Zeit hast, lass es den Gastgeber bitte wissen, 
    damit die anderen Teilnehmer nicht auf die warten müssen. Vielleicht 
    passt es ja ein anderes mal. 
  </p>
  <p>Fröhliches Grimasse schneiden!</p>
   `,
};
