import { consoleLog } from "../util/consoleLog";
import React, { useState, useContext, useEffect } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import { v4 as uuidv4 } from "uuid";
import { getGameListByEmail } from "../util/apiConnect";

import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ParticipantIcon from "@mui/icons-material/People";
import HostIcon from "@mui/icons-material/Engineering";
// import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

import BriefingI18n from "../support/briefingI18n";
import WallOfFame from "../support/wallOfFame";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function PickGame(props) {
  const {
    gameId,
    logInAs,
    photosDispatch,
    setGameId,
    loadGameById,
    setGameExpression,
    setGameHost,
    setGameEndTime,
    setGameStartTime,
    setGameFinished,
    setParticipantsList,
    setStoryboardStep,
    determineStoryboardStep,
    serverTimeNow,
    logContext,
  } = useContext(GameContext);

  const [selectedGameIndex, setSelectedGameIndex] = useState(-1);
  const [myGames, setMyGames] = useState([]);
  const [loadingGames, setLoadingGames] = useState("loading");
  const { t } = useTranslation();

  const fetchGameListByEmail = async (email) => {
    try {
      const gameData = await getGameListByEmail(email);
      //   consoleLog.log(`gameData: ${gameData}`);
      //   consoleLog.log(`gameData[0].gameId: ${gameData[0].gameId}`);
      //   consoleLog.log(`gameData[0].gameHost: ${gameData[0].gameHost}`);
      //   consoleLog.log(`gameData[0].gameExpression: ${gameData[0].gameExpression}`);
      //   consoleLog.log(`gameData[0].gameStartTime: ${gameData[0].gameStartTime}`);
      //   consoleLog.log(`gameData[0].gameEndTime: ${gameData[0].gameEndTime}`);
      setMyGames(gameData);
      if (gameData.length === 0) {
        setLoadingGames("noGames");
      } else {
        setLoadingGames("loaded");
      }
      // consoleLog.log(`gameData: ${gameData.length === 0}`);
    } catch (err) {
      consoleLog.log(err);
      setLoadingGames("noGames");
    }
  };

  function handleCreateGame() {
    setGameId(uuidv4());
    setParticipantsList([]);
    setGameExpression("");
    photosDispatch({ type: "deleteAll" });
    setGameHost("");
    setGameStartTime(0);
    setGameEndTime(0);
    setGameFinished(false);
    trackingEvent("game", "createNewGame");
    determineStoryboardStep();
  }

  function handlePickGame() {
    const gameIdPicked = myGames[selectedGameIndex].gameId;
    trackingEvent("game", "pickGame", "", gameIdPicked);
    loadGameById(gameIdPicked, false);
  }

  function handleRestartGame() {
    const gameIdPicked = myGames[selectedGameIndex].gameId;
    trackingEvent("game", "restartGame", "", gameIdPicked);
    loadGameById(gameIdPicked, true);
    setGameExpression("");
    photosDispatch({ type: "deleteAll" });
    setGameId(uuidv4());
    setGameHost("");
    setGameStartTime(0);
    setGameFinished(false);
    setStoryboardStep("expression");
    //determineStoryboardStep();
  }

  const handleListItemClick = (event, index) => {
    setSelectedGameIndex(index);
  };

  useEffect(() => {
    fetchGameListByEmail(logInAs);
  }, [logInAs]);

  function renderGameItems(gamesList) {
    var currentDateTimeFormat = "en-UK";
    if (i18n.language === "de") {
      currentDateTimeFormat = "de-DE";
    }

    if (loadingGames === "loading") {
      return t("pickGame.loading");
    } else if (loadingGames === "noGames" || gamesList.length === 0) {
      return t("pickGame.loading_failed");
    } else
      return gamesList.map((game, index) => {
        // consoleLog.log(`game.gameId: ${game.gameId}`);
        // consoleLog.log(`game.gameStartTime: ${game.gameStartTime}`);
        return (
          <ListItem value={game.gameId} key={game.gameId} disablePadding>
            <ListItemButton
              selected={selectedGameIndex === index}
              role={undefined}
              onClick={(event) => handleListItemClick(event, index)}
              dense={false}
              style={{ borderRadius: "5px" }}
            >
              <ListItemIcon>
                {game.gameHost === logInAs ? <HostIcon /> : <ParticipantIcon />}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={
                  serverTimeNow() - game.gameEndTime > 86400000 &&
                  game.gameEndTime !== 0 // Todo Hier vielleicht auch Spiele ohne Fotos ausnehmen - falls die mal vorher abgeräumt werden
                    ? { color: "#b0b0b0" }
                    : null
                }
                secondaryTypographyProps={
                  serverTimeNow() - game.gameEndTime > 86400000 &&
                  game.gameEndTime !== 0
                    ? { color: "#c0c0c0" }
                    : null
                }
                primary={game.gameExpression}
                secondary={`${t(
                  "pickGame.list_started_on"
                )} ${Intl.DateTimeFormat(currentDateTimeFormat, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }).format(game.gameStartTime)} ${t(
                  "pickGame.list_started_by"
                )} ${game.gameHost}`}
              />
            </ListItemButton>
          </ListItem>
        );
      });
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("pickGame.meta_title")}
        description={t("pickGame.meta_description")}
      />
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleCreateGame}
          style={{ width: "100%" }}
        >
          {t("pickGame.button_start_game")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {t("pickGame.list_label")}
      </Grid>

      <Grid item xs={12}>
        <FormControl style={{ width: "100%" }}>
          {/* <InputLabel
            shrink
            style={{
              backgroundColor: "#ffffff",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {t("pickGame.list_label")}
          </InputLabel> */}
          <Box
            component="span"
            sx={{ border: "1px solid grey", borderRadius: "5px" }}
          >
            <Grid
              container
              rowSpacing={0}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <Grid item xs={12}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    maxHeight: "300px",
                    overflow: "auto",
                  }}
                >
                  {renderGameItems(myGames)}
                </List>
              </Grid>
            </Grid>
          </Box>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={
            selectedGameIndex === -1 ||
            (serverTimeNow() - myGames[selectedGameIndex].gameEndTime >
              86400000 &&
              myGames[selectedGameIndex].gameEndTime !== 0) // older than one day
          }
          variant="contained"
          onClick={handlePickGame}
          style={{ width: "100%" }}
        >
          {t("pickGame.button_open_game")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={selectedGameIndex === -1}
          variant="outlined"
          onClick={handleRestartGame}
          style={{ width: "100%" }}
        >
          {t("pickGame.button_game_with_prev")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="pickGame" />
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}
export default PickGame;
