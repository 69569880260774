import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function Terms(props) {
  const { determineStoryboardStep } = useContext(GameContext);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title="Terms of use - Grimaces"
        description="Grimaces.net is about making weird faces with your friends. These are the rules for the fun game on grimaces.net"
      />
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("generic.button_back")}
        </Button>
      </Grid>{" "}
      <Grid item xs={12}>
        <Paper
          sx={{ typography: "body2", fontStyle: "italic" }}
          elevation={3}
          style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        >
          <iframe
            frameBorder="0"
            title="terms"
            src="/terms_of_use.html"
            style={{ width: "100%", height: "300px" }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("generic.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default Terms;
