import React from "react";
import PropTypes from "prop-types";
import { Alert, Link } from "@mui/material";

function Result({ message, state }) {
  const INACTIVE = "INACTIVE";
  const SUCCESS = "SUCCESS";
  const ERROR = "ERROR";

  if (state === INACTIVE) {
    return <> </>;
  }
  if (state === SUCCESS) {
    return (
      <Alert severity="success">
        {message ? message : "SUCCESS"}{" "}
        <Link href="/game">--&gt; go to game</Link>
      </Alert>
    );
  }
  if (state === ERROR) {
    return <Alert severity="error">{message ? message : "ERROR!"}</Alert>;
  }
  throw Error("Invalid state: " + state);
}

Result.propTypes = {
  message: PropTypes.string,
  state: PropTypes.string,
};

Result.defaultProps = {
  state: "INACTIVE",
};

export default Result;
