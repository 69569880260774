import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function RenderThumbs({ discloseParticipantPhotos, gameRunning }) {
  const { logInAs, participantsList, photos, gameHost } =
    useContext(GameContext);
  //   consoleLog.log(`myPhoto.user ${myPhoto.user}`);
  //   consoleLog.log(`myPhoto.jpg ${myPhoto.jpg}`);
  const { t } = useTranslation();
  const myPhoto = photos.find((o) => o.user === logInAs);
  const hostPhoto = photos.find((o) => o.user === gameHost);

  function renderParticipantStatus(participant) {
    // consoleLog.log(JSON.stringify(participant));
    if (participant === gameHost) {
      return `${gameHost} ${t("gameStatus.participant_status_host")}`;
    } else if (!gameRunning) {
      return `${participant.email} ${t(
        "gameStatus.participant_status_noShow"
      )}`;
    } else if (participant.status === "invited") {
      return `${t("gameStatus.participant_status_waiting_for")} ${
        participant.email
      } ${t("gameStatus.participant_status_to_contribute")}`;
    } else if (participant.status === "declined") {
      return `${participant.email} ${t(
        "gameStatus.participant_status_rain_check"
      )}`;
    } else return participant.email;
  }

  function renderParticipant(participant) {
    // consoleLog.log(`participant in renderParticipant ${participant}`);
    return (
      <Box
        key={participant.email}
        component="span"
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "5px",
          minWidth: "100px",
          minHeight: "100px",
          maxWidth: "150px",
          maxHeight: "150px",
          marginRight: "5px",
          marginBottom: "5px",
          padding: "3px",
          wordWrap: "break-word",
          typography: "body2",
          fontSize: 10,
          color: "#808080",
        }}
      >
        {renderParticipantStatus(participant)}
      </Box>
    );
  }

  function renderPhoto(photo) {
    if (photo === undefined) {
      return null;
    } else {
      return (
        <img
          key={photo.user}
          src={photo.jpg}
          style={{
            border: "1px solid lightgrey",
            borderRadius: "5px",
            // minWidth: "100px",
            minHeight: "100px",
            // maxWidth: "150px",
            maxHeight: "150px",
            marginRight: "5px",
            marginBottom: "5px",
          }}
          alt={`Grimace of ${photo.user}`}
        />
      );
    }
  }

  function renderHost() {
    // if (logInAs === gameHost || !gameRunning) {
    return renderPhoto(hostPhoto);
    // } else {
    //   return renderParticipant(gameHost);
    // }
  }

  function renderOthers() {
    return participantsList.map((participant) => {
      if (participant.email !== logInAs) {
        var photo = photos.find((o) => o.user === participant.email);
        // if (photo === undefined || !discloseParticipantPhotos) {
        if (photo === undefined) {
          return renderParticipant(participant);
        } else {
          return renderPhoto(photo);
        }
      } else return null;
    });
  }

  return (
    <Grid container item xs={12}>
      {/* Zuerst wird der Host gezeigt - für alle aber erst wenn das Spiel um ist 
      Dann wird das eigene Bild gezeigt. Dann andere participants */}
      {renderHost()}
      {logInAs !== gameHost ? renderPhoto(myPhoto) : null}
      {renderOthers()}
    </Grid>
  );
}
export default RenderThumbs;
