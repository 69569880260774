export const i18n_participant_photo_preview_en = {
  meta_title: "Contribute your photo - grimaces.net",
  meta_description:
    "Take another shot or contribute your grimace to the game. We will inform you when the game ends and you can see the results",
  button_contribute: "Contribute your photo to the game",
  button_retake: "Take another shot - I can do better",
  briefing_headline: "You look awesome!",
  briefing_text: `
    <p>Now contribute your photo to the game!</p>
    <p>We will inform the game host that you joined the game and 
    will let you know when the game ends and all grimaces are disclosed!</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_participant_photo_preview_de = {
  meta_title: "Teile Dein Foto für das Spiel - grimaces.net",
  meta_description:
    "Lade Dein Bild hoch und nimm am Spiel teil - oder nimm ein besseres Foto auf.",
  button_contribute: "Contribute your photo to the game",
  button_retake: "Take another shot - I can do better",
  briefing_headline: "Du siehst super aus!",
  briefing_text: `
    <p>Lade Dein Bild hoch und nimm am Spiel teil - oder nimm ein besseres Foto auf.</p>
    <p>Wir werden Dich informieren, sobald das Spiel endet und die Grimassen Deiner Freunde sichtbar werden. Das wird ein Spaß!!!</p>
    <p>Fröhliches Grimasse schneiden!</p>
`,
};
