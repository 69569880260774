import React, { useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import UserList from "../support/userList";
import BriefingI18n from "../support/briefingI18n";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function ListParticipants(props) {
  const {
    setStoryboardStep,
    determineStoryboardStep,
    friendsList,
    participantsList,
    setParticipantsList,
  } = useContext(GameContext);
  const [usersChecked, setUsersChecked] = useState(
    participantsList.map((user) => user.email)
  );
  const { t } = useTranslation();

  function handleStartGame() {
    trackingEvent("game", "pickParticipants", "", usersChecked.length);
    var checked2Participants = [];
    usersChecked.map((user) => {
      return checked2Participants.push({ email: user, status: "picked" });
    });
    setParticipantsList(checked2Participants);
    determineStoryboardStep();
  }

  function handleAddFriend() {
    setStoryboardStep("friends");
    trackingEvent("account", "go2AddFriends");
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("participants.meta_title")}
        description={t("participants.meta_description")}
      />
      <Grid item xs={12}>
        <UserList
          maxHeight={350}
          listLabel={t("participants.list_label")}
          style={{ width: "100%" }}
          friendsList={friendsList}
          usersChecked={usersChecked}
          setUsersChecked={setUsersChecked}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={usersChecked.length === 0}
          variant="contained"
          onClick={handleStartGame}
          style={{ width: "100%" }}
        >
          {t("participants.button_play_game")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={handleAddFriend}
          style={{ width: "100%" }}
        >
          {t("participants.button_add_remove_friends")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="participants" />
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}
export default ListParticipants;
