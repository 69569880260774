import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";
import RenderThumbs from "../support/renderThumbs";

function GameStatus(props) {
  const {
    setStoryboardStep,
    setParticipantsList,
    gameExpression,
    setGameExpression,
    photosDispatch,
    photoContributed,
    showAds,
    subscribed,
    setGameId,
    setGameHost,
    setGameStartTime,
    setGameEndTime,
    setGameFinished,
  } = useContext(GameContext);
  const { t } = useTranslation();

  function callCarousel() {
    if (showAds) {
      setStoryboardStep("adsense");
    } else {
      setStoryboardStep("gallery");
    }
  }

  // Reset game mal in den Context packen
  function startNewGame() {
    trackingEvent("game", "startAnotherGame");
    setParticipantsList([]);
    setGameExpression("");
    photosDispatch({ type: "deleteAll" });
    setGameId("");
    setGameHost("");
    setGameStartTime(0);
    setGameEndTime(0);
    setGameFinished(false);
  }

  function go2ContributePhoto() {
    trackingEvent("other", "go2ContributePhoto");
    setStoryboardStep("contributePhoto");
  }
  function go2Subscribe() {
    trackingEvent("other", "go2Subscribe");
    setStoryboardStep("subscribe");
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("gameFinished.meta_title")}
        description={t("gameFinished.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="expression"
          value={gameExpression}
          label={t("gameFinished.textfield_label")}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <RenderThumbs discloseParticipantPhotos={true} gameRunning={false} />
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            callCarousel();
            trackingEvent("gallery", "openGallery");
            trackingEvent("other", "go2TalkAbout");
          }}
          style={{ width: "100%" }}
        >
          {t("gameFinished.button_enlarge_photos")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          disabled={photoContributed}
          onClick={() => {
            go2ContributePhoto();
          }}
          style={{ width: "100%" }}
        >
          {t("gameFinished.button_contribute")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            startNewGame();
          }}
          style={{ width: "100%" }}
        >
          {t("gameFinished.button_restart")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            trackingEvent("other", "go2TalkAbout");
            alert(
              "This page does not exist yet. We will provide means to share your excitement about grimaces.net. In the meantime, please tell your friends..."
            );
          }}
          style={{ width: "100%" }}
        >
          {t("gameFinished.button_talk_about")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          disabled={subscribed}
          onClick={() => {
            go2Subscribe();
          }}
          style={{ width: "100%" }}
        >
          {t("gameFinished.button_subscribe")}
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "10px" }}>
        <BriefingI18n briefing="gameFinished" />
      </Grid>
    </Grid>
  );
}
export default GameStatus;
