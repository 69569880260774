import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation, Trans } from "react-i18next";

function BriefingI18n({ briefing, values }) {
  const { t } = useTranslation();

  if (!briefing) return null;
  return (
    <Paper
      sx={{ typography: "body2", fontStyle: "italic" }}
      elevation={3}
      style={{ width: "100%" }}
    >
      <div style={{ padding: "20px" }}>
        <Typography sx={{ typography: "h6" }}>
          {t(`${briefing}.briefing_headline`)}
        </Typography>
        <p></p>
        <Trans i18nKey={`${briefing}.briefing_text`} values={values}></Trans>
      </div>
    </Paper>
  );
}
export default BriefingI18n;
