export const i18n_login_en = {
  meta_title: "Login to grimaces.net to play the fun game",
  meta_description:
    "Login to Grimaces.net to host your game - make weird faces and share with your friends",
  textfield_label:
    "Enter eMail address to log in or sign up for a fun game of grimaces...",
  button_action_in: "Login",
  button_action_out: "Logout",
  button_next_loggedIn: "Play the game",
  button_next_loggedOut: "Sign up to play the game",
  briefing_headline: "What is this about? What do I do?",
  briefing_text: `<p>It's about what you did as a child: Making funny faces.</p>
  <p>Start the game and come up with an expression - e.g. 'Ouch - that DID hurt!!',
  'You must be an angel...' or 'Did you just call me lame?!!' - think of anything.</p>
  <p>Start your camera and make the face matching the expression.
  Then invite your friends to do the same. We promise: 
  They will look awesome! It is going to be fun!</p>
  <p>Happy grimacing!</p>`,
};

export const i18n_loginPwd_en = {
  meta_title: "Login - grimaces.net",
  meta_description:
    "Login to Grimaces.net to host your game - make weird faces and share with your friends",
  textfield_label: "Password for",
  button_login: "Login",
  button_other_user: "Login with different email address",
  button_pwd_forgot: "Forgot password",
};

export const i18n_loginPwdForgot_en = {
  meta_title: "Forgot password - grimaces.net",
  meta_description:
    "Don't make such a grumpy face. We sent you a link to reset your password already. Login to Grimaces.net to host your game - make weird faces and share with your friends",
  button_back: "Back to Login",
  briefing_headline: "Reset your password",
  briefing_text: `
  <p>Don't look so grumpy. We already sent you an eMail with a link to reset your password.
  Please check your mailbox or the spam filter in a couple of minutes.</p>
  <p>Happy grimacing!</p>
  `,
};

export const i18n_signup_en = {
  meta_title: "Sign Up - grimaces.net",
  meta_description:
    "Sign up to play a game of grimaces on Grimaces.net - make weird faces and share with your friends",
  textfield1_label: "Choose your password",
  textfield2_label: "Repeat password",
  button_signup: "I accept the terms of use. Let's go!",
  button_back: "Back to Login",
  pwd_dont_match: "Password and repeated password don't match!",
};

export const i18n_signupConfirm_en = {
  meta_title: "Confirm sign up - grimaces.net",
  meta_description:
    "The show is about to start. We sent you a link to confirm your registration. Login to Grimaces.net to host your game - make weird faces and share with your friends",
  button_back: "Back to Login",
  briefing_headline: "Confirm your registration",
  briefing_text: `
  <p>The show is about to start. We sent you an eMail with a link to confirm your registration. 
  Please check your mailbox or the spam filter in a couple of minutes.</p>
  <p>Happy grimacing!</p>`,
};

export const i18n_login_de = {
  meta_title: "Bei grimaces.net anmelden und ein Spiel starten",
  meta_description:
    "Melde Dich bei grimaces.net um ein Spiel auszurichten und Freunde einzuladen. Das wird ein Spaß!",
  textfield_label:
    "Gib Deine eMail Adresse an, um Dich anzumelden oder zu registrieren",
  button_action_in: "Anmelden",
  button_action_out: "Abmelden",
  button_next_loggedIn: "Spiel starten",
  button_next_loggedOut: "Registrieren, um ein Spiel zu starten",
  briefing_headline: "Klingt gut! Was muss ich machen?",
  briefing_text: `<p>Es könnte nicht einfacher sein:</p>
  <p>Starte das Spiel und überlege, was Du ausdrücken möchtest - wir nennen das einen Grimsel. Zum Beispiel 'Autsch - das tat weh!',
  'Habe ich gerade einen Engel gesehen' or 'Ey, hast Du mich gerade Trottel genannt?!!' - Denk Dir irgendwas aus.</p>
  <p>Starte Deine Kamera und mach ein Selfie passend zum Grimsel.
  Dann lade Deine Freunde ein, das gleiche zu machen. Wir versprechen Dir: 
  Das wird sehr lustig!</p>
  <p>Fröhliches Grimassen schneiden!</p>`,
};

export const i18n_loginPwdForgot_de = {
  meta_title: "Passwort vergessen - grimaces.net",
  meta_description: `Wir haben Dir einen Link geschickt, um Dein Passwort zurückzusetzen.`,
  button_back: "Zurück zur Anmeldung",
  briefing_headline: "Passwort zurücksetzen",
  briefing_text: `
  <p>Jetzt guck nicht so bedröppelt. Obwohl... Die Grimasse ist schon ganz gut...<p/>
  <p>Jedenfalls haben wir Dir schon einen Link geschickt, um Dein Passwort zurückzusetzen. 
  Wenn die Mail in wenigen Minuten nicht ankommt, schau doch mal im Spam-Filter</p>
  `,
};

export const i18n_loginPwd_de = {
  meta_title: "Bei grimaces.net anmelden und ein Spiel starten",
  meta_description:
    "Melde Dich bei grimaces.net um ein Spiel auszurichten und Freunde einzuladen. Das wird ein Spaß!",
  textfield_label: "Passwort für",
  button_login: "Anmeldung",
  button_other_user: "Mit einer anderen eMail anmelden",
  button_pwd_forgot: "Passwort vergessen",
};

export const i18n_signup_de = {
  meta_title: "Registrieren - grimaces.net",
  meta_description:
    "Registriere Dich um ein Spiel auf grimaces.net zu spielen. Was ein Spaß!",
  textfield1_label: "Passwort wählen",
  textfield2_label: "Passwort wiederholen",
  button_signup: "Ich akzeptiere die Nutzungbedigungen. Los jetzt!",
  button_back: "Zurück zur Anmeldung",
  pwd_dont_match:
    "Passwort und Wiederholung des Passworts stimmen nicht überein.",
};

export const i18n_signupConfirm_de = {
  meta_title: "Registrierungsbestätigung - grimaces.net",
  meta_description:
    "Jetzt geht die Show bald los. Wir haben Dir einen Link zur Bestätigung Deiner Registrierung geschickt. Meld Dich an und locker Dein Gesicht - jetzt werden Grimassen geschnitten!",
  button_back: "Zurück zur Anmeldung",
  briefing_headline: "Bestätige Deine Registrierung",
  briefing_text: `
  <p>Jetzt geht die Show bald los. Wir haben Dir einen Link zur Bestätigung Deiner Registrierung geschickt. Meld Dich an und locker Dein Gesicht - jetzt werden Grimassen geschnitten!</p>
  <p>Fröhliches Grimassen schneiden!</p>`,
};
