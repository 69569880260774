import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import WallOfFame from "../support/wallOfFame";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

function ContributePhoto(props) {
  const { logInAs, photos, gameId, contributePhoto, determineStoryboardStep } =
    useContext(GameContext);
  // consoleLog.log(photos === undefined);
  const myPhoto = photos.find((photo) => photo.user === logInAs);
  const { t } = useTranslation();

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("contribute.meta_title")}
        description={t("contribute.meta_description")}
      />
      <Grid item xs={12}>
        <img
          key={myPhoto.user}
          src={myPhoto.jpg}
          style={{
            border: "1px solid grey",
            minWidth: "150px",
            minHeight: "150px",
            maxWidth: "250px",
            maxHeight: "250px",
            marginBottom: "5px",
            width: "100%",
          }}
          alt={`${t("contribute.grimace_of")} ${myPhoto.user}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            trackingEvent("other", "contributePhoto");
            contributePhoto(gameId, logInAs);
          }}
          style={{ width: "100%" }}
        >
          {t("contribute.button_contribute")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("contribute.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="contribute" />
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}
export default ContributePhoto;
