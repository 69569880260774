import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { i18n_generic_en, i18n_generic_de } from "../const/i18n_generic";
import { i18n_homepage_en, i18n_homepage_de } from "../const/i18n_homepage";
import {
  i18n_login_en,
  i18n_login_de,
  i18n_loginPwd_en,
  i18n_loginPwd_de,
  i18n_loginPwdForgot_en,
  i18n_loginPwdForgot_de,
  i18n_signup_de,
  i18n_signup_en,
  i18n_signupConfirm_en,
  i18n_signupConfirm_de,
} from "../const/i18n_account";
import { i18n_pickGame_en, i18n_pickGame_de } from "../const/i18n_pickGame";
import {
  i18n_participants_en,
  i18n_participants_de,
  i18n_addRemoveFriends_en,
  i18n_addRemoveFriends_de,
} from "../const/i18n_participantsAndFriends";
import {
  i18n_expression_en,
  i18n_expression_de,
} from "../const/i18n_expression";
import { i18n_takePhoto_en, i18n_takePhoto_de } from "../const/i18n_takePhoto";
import {
  i18n_takePhoto_participant_en,
  i18n_takePhoto_participant_de,
} from "../const/i18n_takePhoto_participant";
import { i18n_startGame_en, i18n_startGame_de } from "../const/i18n_startGame";
import {
  i18n_gameStatus_en,
  i18n_gameStatus_de,
} from "../const/i18n_gameStatus";
import {
  i18n_gameFinished_en,
  i18n_gameFinished_de,
} from "../const/i18n_gameFinished";
import {
  i18n_contribute_en,
  i18n_contribute_de,
} from "../const/i18n_contribute";
import { i18n_subscribe_en, i18n_subscribe_de } from "../const/i18n_subscribe";
import {
  i18n_wall_of_fame_en,
  i18n_wall_of_fame_de,
} from "../const/i18n_wall_of_fame";
import {
  i18n_participant_photo_preview_en,
  i18n_participant_photo_preview_de,
} from "../const/i18n_participant_photo_preview";
import {
  i18n_participant_consent_en,
  i18n_participant_consent_de,
} from "../const/i18n_participant_consent";
import {
  i18n_participant_needs_signup_en,
  i18n_participant_needs_signup_de,
} from "../const/i18n_participant_needs_signup";
import { i18n_imprint_en, i18n_imprint_de } from "../const/i18n_imprint";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    // debug: true,
    // lng: "de", // auskommentieren damit languagedetector greift.
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          generic: i18n_generic_en,
          homepage: i18n_homepage_en,
          login: i18n_login_en,
          loginPwd: i18n_loginPwd_en,
          loginPwdForgot: i18n_loginPwdForgot_en,
          signup: i18n_signup_en,
          signupConfirm: i18n_signupConfirm_en,
          pickGame: i18n_pickGame_en,
          participants: i18n_participants_en,
          addRemoveFriends: i18n_addRemoveFriends_en,
          expression: i18n_expression_en,
          takePhoto: i18n_takePhoto_en,
          takePhoto_participant: i18n_takePhoto_participant_en,
          startGame: i18n_startGame_en,
          gameStatus: i18n_gameStatus_en,
          gameFinished: i18n_gameFinished_en,
          contribute: i18n_contribute_en,
          subscribe: i18n_subscribe_en,
          wall_of_fame: i18n_wall_of_fame_en,
          participant_photo_preview: i18n_participant_photo_preview_en,
          participant_consent: i18n_participant_consent_en,
          participant_needs_signup: i18n_participant_needs_signup_en,
          imprint: i18n_imprint_en,
        },
      },
      de: {
        translation: {
          generic: i18n_generic_de,
          homepage: i18n_homepage_de,
          login: i18n_login_de,
          loginPwd: i18n_loginPwd_de,
          loginPwdForgot: i18n_loginPwdForgot_de,
          signup: i18n_signup_de,
          signupConfirm: i18n_signupConfirm_de,
          pickGame: i18n_pickGame_de,
          participants: i18n_participants_de,
          addRemoveFriends: i18n_addRemoveFriends_de,
          expression: i18n_expression_de,
          takePhoto: i18n_takePhoto_de,
          takePhoto_participant: i18n_takePhoto_participant_de,
          startGame: i18n_startGame_de,
          gameStatus: i18n_gameStatus_de,
          gameFinished: i18n_gameFinished_de,
          contribute: i18n_contribute_de,
          subscribe: i18n_subscribe_de,
          wall_of_fame: i18n_wall_of_fame_de,
          participant_photo_preview: i18n_participant_photo_preview_de,
          participant_consent: i18n_participant_consent_de,
          participant_needs_signup: i18n_participant_needs_signup_de,
          imprint: i18n_imprint_de,
        },
      },
    },
  });

export default i18n;
