import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";
import WallOfFame from "../support/wallOfFame";

function Imprint(props) {
  const { determineStoryboardStep } = useContext(GameContext);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("wall_of_fame.meta_title")}
        description={t("wall_of_fame.meta_description")}
      />
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("wall_of_fame.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={100} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("wall_of_fame.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="wall_of_fame" />
        <p />{" "}
      </Grid>
    </Grid>
  );
}

export default Imprint;
