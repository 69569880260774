import { useState, useEffect } from "react";
// import * as ConstantAssets from "./const/assets";
import { GRIMACES } from "../const/assets";
import Grid from "@mui/material/Grid";

function WallOfFame({ itemCount, refreshGrimaces }) {
  const imgPath = "/img/wallOfFame/";
  const [grimacesPicked, setGrimacesPicked] = useState([]);
  if (itemCount > GRIMACES.length) {
    itemCount = GRIMACES.length;
  }

  useEffect(() => {
    const shuffled = GRIMACES.sort(() => 0.5 - Math.random());
    const picked = shuffled.slice(0, itemCount);
    setGrimacesPicked(picked);
  }, [itemCount, refreshGrimaces]);

  function renderItems() {
    return grimacesPicked.map((element) => {
      //consoleLog.log(element.jpg);
      return (
        <Grid item xs={2} key={element.jpg}>
          <img
            src={imgPath + element.jpg}
            alt={`Grimace taken by ${element.photographer}`}
            style={{
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "5px",
            }}
          />
        </Grid>
      );
    });
  }

  return (
    <Grid container spacing={1} columns={10}>
      {renderItems()}
    </Grid>
  );
}

export default WallOfFame;
