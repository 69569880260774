import React, { Component } from "react";
// import { consoleLog } from "../util/consoleLog";

class AdsenseFullscreen extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <div>
        <ins
          className="adsbygoogle"
          //   style={{ display: "inline-block", width: "600px", height: "300px" }}
          style={{ display: "inline-block", width: "100%" }}
          data-ad-client="ca-pub-8733317590688045"
          data-ad-slot="7784433781"
          //   data-ad-format="auto"
          data-adtest="on"
          data-ad-format="rectangle"
          data-full-width-responsive="false"
        ></ins>
      </div>
    );
  }
}
export default AdsenseFullscreen;
