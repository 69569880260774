import { consoleLog } from "../util/consoleLog";
import React, { useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import { fetchEmailsFromPrevGames } from "../util/apiConnect";

import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";

import UserList from "../support/userList";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

function AddRemoveFriends(props) {
  const {
    setStoryboardStep,
    logInAs,
    friendsList,
    setFriendsList,
    setFriendsListUpdated,
    setParticipantsList,
  } = useContext(GameContext);

  const [previousEmailsLoading, setPreviousEmailsLoading] = useState(false);
  const [usersChecked, setUsersChecked] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [newEmailDuplicate, setNewEmailDuplicate] = useState(false);
  const [newEmailButtonDisabled, setNewEmailButtonDisabled] = useState(true);
  const [newEmailLabel, setNewEmailLabel] = useState("New eMail address");
  const [renderDelete, setRenderDelete] = useState(false);
  const { t } = useTranslation();

  function handleEmailChange(e) {
    if (friendsList.find((eMail) => eMail === e.target.value) !== undefined) {
      setNewEmailDuplicate(true);
      setNewEmailButtonDisabled(true);
      setNewEmailLabel(t("addRemoveFriends.label_email_already_listed"));
    } else if (e.target.value === "") {
      setNewEmailDuplicate(false);
      setNewEmailButtonDisabled(true);
      setNewEmailLabel(t("addRemoveFriends.label_new_email"));
    } else {
      setNewEmailDuplicate(false);
      setNewEmailButtonDisabled(false);
      setNewEmailLabel(t("addRemoveFriends.label_new_email"));
    }
    setNewEmail(e.target.value);
  }

  function addEmail() {
    // const newFriendsList = friendsList;
    if (friendsList.find((email) => email === newEmail) === undefined) {
      setFriendsList([...friendsList, newEmail].sort());
      setFriendsListUpdated(true); // triggers database update
      trackingEvent("users", "addEmail");
      setStoryboardStep("participants");
    }
  }

  const getEmailsFromPrevGames = async () => {
    trackingEvent("account", "getEmailsFromPrevGames");
    try {
      setPreviousEmailsLoading(true);
      var fetchedEMails = await fetchEmailsFromPrevGames(logInAs);
      consoleLog.log(`fetchedEMails: ${JSON.stringify(fetchedEMails)}`);
      var newEmails = [];
      fetchedEMails.map((newEmail) => {
        if (
          friendsList.find((email) => email === newEmail) === undefined &&
          newEmail !== logInAs
        ) {
          newEmails.push(newEmail);
        }
        return newEmail;
      });
      // consoleLog.log(`newEmails: ${newEmails}`);
      if (newEmails.length > 0) {
        setFriendsList(friendsList.concat(newEmails).sort());
        setFriendsListUpdated(true); // triggers database update
      }
      setStoryboardStep("participants");
    } catch (err) {
      trackingEvent("nonFatal", "exception", "getEmailsFromPrevGames", err);
      consoleLog.log(err);
    } finally {
      setPreviousEmailsLoading(false);
    }
  };

  function handleDelete() {
    var newFriendsList = [];
    friendsList.forEach((friend) => {
      if (usersChecked.find((checked) => checked === friend) === undefined) {
        newFriendsList = [...newFriendsList, friend];
      }
    });
    // consoleLog.log(usersChecked);
    // consoleLog.log(newFriendsList);
    setFriendsList(newFriendsList);
    setParticipantsList([]);
    setFriendsListUpdated(true); // triggers database update
    trackingEvent("account", "deleteEmails");
  }

  function renderDeleteFriends() {
    if (!renderDelete) {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              setRenderDelete(true);
            }}
            style={{ width: "100%", marginTopo: "20px" }}
          >
            {t("addRemoveFriends.label_delete_friends")}
          </Button>
        </Grid>
      );
    } else {
      return (
        <>
          <Grid item xs={12}>
            <div style={{ marginBottom: "5px" }}>
              {t("addRemoveFriends.label_delete_friends")}
            </div>
            <UserList
              maxHeight={250}
              // listLabel={t("addRemoveFriends.list_label")}
              style={{ width: "100%" }}
              friendsList={friendsList}
              usersChecked={usersChecked}
              setUsersChecked={setUsersChecked}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={usersChecked.length === 0}
              onClick={handleDelete}
              style={{ width: "100%" }}
            >
              {t("addRemoveFriends.button_delete")}
            </Button>
          </Grid>
        </>
      );
    }
  }

  function handleBack() {
    setStoryboardStep("participants");
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("addRemoveFriends.meta_title")}
        description={t("addRemoveFriends.meta_description")}
      />
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{ width: "100%" }}
        >
          {t("addRemoveFriends.button_back_to_game")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {/* {t("addRemoveFriends.label_add_friends")} */}
        <Textfield
          id="newEmail"
          value={newEmail}
          error={newEmailDuplicate}
          label={newEmailLabel}
          variant="outlined"
          style={{ width: "100%", marginTop: "6px" }}
          onChange={handleEmailChange}
        />
        <Button
          id="newEmailButton"
          variant="contained"
          disabled={newEmailButtonDisabled}
          onClick={addEmail}
          style={{ width: "100%", marginTop: "3px", marginBottom: "3px" }}
        >
          {t("addRemoveFriends.button_add_email")}
        </Button>
        <Button
          id="newEmailButton"
          variant="outlined"
          disabled={previousEmailsLoading}
          onClick={getEmailsFromPrevGames}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {previousEmailsLoading
            ? t("addRemoveFriends.button_loading")
            : t("addRemoveFriends.button_add_prev")}
        </Button>
      </Grid>
      {renderDeleteFriends()}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{ width: "100%" }}
        >
          {t("addRemoveFriends.button_back_to_game")}
        </Button>
      </Grid>{" "}
      <Grid item xs={12}>
        <BriefingI18n briefing="addRemoveFriends" />
      </Grid>
    </Grid>
  );
}
export default AddRemoveFriends;
