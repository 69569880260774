export const i18n_imprint_en = {
  meta_title: "Imprint - grimaces.net",
  meta_description:
    "Grimaces.net is about making weird faces with your friends. Read here who we are at grimaces.net",
  button_back: "Zurück",
  briefing_headline: "Imprint",
  briefing_text: `
    <p>grimaces.net is a pleasantt nonsense brought to you by:</p>
    <p>actyvyst GmbH<br />
    Gerresheimer Straße 153, 40233 Düsseldorf<br />
    General Manager: Thomas Görldt, Ralf Siewert<br />
    Handelsregister beim Amtsgericht Düsseldorf Registernummer: HRB 86132<br />
    Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz: DE324741447
    </p>
    <p>contact@actyvyst.com<br />
    Tel. +49(0)211/51429081
    </p>
    <p>Idea: Ulrich Klostermann<br />
    Implementation: Ulrich Klostermann, Ralf Siewert, Sascha Rose<br />
    </p>
    <p>Responsible for the content:<br />
    Ralf Siewert, <br />
    Dorotheenstraße 39,<br />
    53111 Bonn
    </p>
    Credit:
    Photos in footer by players on grimaces.net and from UNSPLASH.COM: {{credits}}
`,
};

export const i18n_imprint_de = {
  meta_title: "Imprint - grimaces.net",
  meta_description:
    "Grimaces.net is about making weird faces with your friends. Read here who we are at grimaces.net",
  button_back: "Zurück",
  briefing_headline: "Impressum",
  briefing_text: `
    <p>grimaces.net ist ein wohltuender Unfung von der actyvyst GmbH für Euch:</p>
    <p>actyvyst GmbH <br />
    Gerresheimer Straße 153, 40233 Düsseldorf<br />
    Geschäftsführer: Thomas Görldt, Ralf Siewert<br />
    Handelsregister beim Amtsgericht Düsseldorf Registernummer: HRB 86132<br />
    Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz: DE324741447
    </p>
    <p>contact@actyvyst.com<br />
    Tel. +49(0)211/51429081
    </p>
    <p>Idee: Ulrich Klostermann<br />
    Umsetzung: Ulrich Klostermann, Ralf Siewert, Sascha Rose<br />
    </p>
    <p>Inhaltliche Verantwortung:<br />
    Ralf Siewert, <br />
    Dorotheenstraße 39,<br />
    53111 Bonn
    </p>
    Credits:
    Die Fotos im Footer sind von Spielern auf grimaces.net und von UNSPLASH.COM: {{credits}}
`,
};
