import Grid from "@mui/material/Grid";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function GrimaceBanner() {
  const imgPath = "/img/";
  const grimaces = [
    "ospan-ali-ufgR_EHJOUw-unsplash.jpg",
    "ospan-ali-_ZXdnKTHPGs-unsplash.jpg",
    "ospan-ali-5zigAp0ng4s-unsplash.jpg",
    "ospan-ali-GUmRXc-vOxw-unsplash.jpg",
    "ospan-ali-i-y1Q7i3faI-unsplash.jpg",
    "ospan-ali-t1JfI6SyEhM-unsplash.jpg",
  ];

  function renderCarouselItems(name) {
    const shuffeledGrimaces = grimaces.sort((a, b) => 0.5 - Math.random());
    return shuffeledGrimaces.map((photo, index) => {
      return (
        <div key={name + index}>
          <img
            src={imgPath + photo}
            alt={`This could be your grimace!`}
            style={{ borderRadius: "5px" }}
          />
        </div>
      );
    });
  }

  function renderCarousel(name, interval) {
    return (
      <Carousel
        autoPlay
        interval={interval}
        infiniteLoop
        swipeable={false}
        renderIndicator={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        useKeyboardArrows={false}
      >
        {renderCarouselItems({ name })}
      </Carousel>
    );
  }

  return (
    <Grid container spacing={1} columns={11}>
      <Grid item xs={3}>
        {renderCarousel("1", 2200)}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        {renderCarousel("1", 3800)}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        {renderCarousel("1", 3000)}
      </Grid>
    </Grid>
  );
}

export default GrimaceBanner;
