export default function trackingEvent(
  thisCategory,
  thisEvent,
  thisAction,
  thisValue,
  thisGameId
) {
  // consoleLog.log("ERROR in util/tracking");
  // consoleLog.log(thisValue);

  if (thisEvent === "exception") {
    const fatality = thisCategory === "fatal" ? true : false;
    window.dataLayer.push({
      event: thisEvent,
      category: thisCategory,
      action: thisAction,
      value: JSON.stringify({
        fatal: fatality,
        gameId: thisGameId,
        description: thisValue,
      }), // TODO: Mal schauen, ob das komplette Object als value in Analytics landet oder ob da der interessante Teil truncated wird. Falls ja, dann hier das interessante aus dem Fehler rausziehen und schicken
    });
  } else {
    window.dataLayer.push({
      event: thisEvent,
      category: thisCategory,
      action: thisAction,
      value: thisValue,
    });
  }
}
