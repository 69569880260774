export const consoleLog = (function () {
  let allowLogging =
    process.env.REACT_APP_ENABLE_CONSOLE_LOG === "true" ? true : false;
  return {
    setAllowLogging: (mode) => {
      allowLogging = mode;
    },
    log: (input) => {
      if (allowLogging) {
        console.log(input);
      }
    },
  };
})();
