export const i18n_homepage_en = {
  meta_title: "grimaces.net - a pleasant nonsense - a fun game",
  meta_description:
    "grimaces.net is about what you loved as a kit: grimacing. Think of an expression, make a matching face, invite your friends to do the same, share the photos. So easy and so much fun!",
  intro1_headline: "grimaces.net is a fun game - a pleasant nonsense!",
  intro1_text1:
    "It's about what you did as a kid: making weird faces about everything. <br />This all-time classic comes back as a game:",
  intro1_bullet1: "Think of an expression,",
  intro1_bullet2: "make a selfie of the matching face,",
  intro1_bullet3: "invite your friends to join in,",
  intro1_bullet4: "share your selfies in the group.",
  intro1_text2: "Just try it - you will enjoy it. It is so much fun...",
  img_alt_default: "A grimace is easily done and some much fun!",
  intro2_headline: "Excellent! Where do I start?",
  intro2_text:
    "We will launch the game shortly. If you want to join our beta test, just leave your eMail address and we keep you in the loop.",
  subscribe_textfield_label: "Enter eMail address to stay informed...",
  subscribe_button_signup:
    "Yes, I'd like to be informed when the game is launched",
  subscribe_button_error:
    "Sorry. We couldn't save your address. Please try again later",
  subscribe_button_success: "Thank you. We keep you posted...",
  wall_of_fame_headline: "Sign up and join the wall of fame",
  wall_of_fame_text:
    "Privacy is important and we delete all photos after every game unless you contribute them to join the wall of fame. The top 100 will make it to the top - be one of them. Here's a sneak preview...",
  imprint:
    "Copyright 2021. grimaces.net is a pleasant nonsense brought to you by actyvyst GmbH",
};

export const i18n_homepage_de = {
  meta_title: "grimaces.net ist ein Spaß - ein wohltuender Unfug!",
  meta_description:
    "Als Kinder haben wir es immer gemacht: Grimassen geschnitten und uns köstlich amüsiert - und genau das machen wir in diesem Spiel: Denk Dir einen Spruch aus, mach ein Selfie mit dem passenden Gesicht und lad Deine Freunde ein, das gleiche zu tun. So einfach - so ein Spaß!",
  intro1_headline: "grimassen.net ist ein Spaß - ein wohltuender Unfug!",
  intro1_text1:
    "Als Kinder haben wir es immer gemacht: Grimassen geschnitten und uns köstlich amüsiert - und genau das machen wir in diesem Spiel:",
  intro1_bullet1: "Überlege Dir, was Du ausdrücken möchtest,",
  intro1_bullet2: "mach ein Selfie vom passenden Gesicht,",
  intro1_bullet3: "lade Deine Freunde ein, auch ein Selfie aufzunehmen",
  intro1_bullet4: "teilt die Photos in der Gruppe.",
  intro1_text2: "Probiert es einfach aus - es ist so ein Spaß! Versprochen!",
  img_alt_default: "Eine Grimasse ist schnell geschnitten und so viel Spaß!",
  intro2_headline: "Ausgezeichnet! Was muss ich machen?",
  intro2_text:
    "Wir werden das Spiel bald starten. Wenn Du beim Beta Testprogramm mitmachen möchtest, trage Deine eMail ein und wir halten Dich auf dem Laufenden",
  subscribe_textfield_label:
    "Gib Deine eMail-Adresse ein, um informiert zu bleiben...",
  subscribe_button_signup:
    "Ja, ich möchte informiert werden, sobald ich mitspielen kann",
  subscribe_button_error:
    "Entschuldige, das Speichern hat nicht geklappt. Bitte versuche es später wieder",
  subscribe_button_success: "Danke Dir. Wir halten Dich auf dem Laufenden",
  wall_of_fame_headline: "Anmelden und in den Top 100 landen!",
  wall_of_fame_text:
    "Datenschutz ist uns wichtig - wir löschen alle Fotos nach dem Spiel wieder. Außer ihr gebt Eure Grimasse frei und bewerbt Euch für die Top 100 - ein noch viel größerer Spaß. Hier eine kleine Auswahl...",
  imprint:
    "Copyright 2021. grimassen.net ist ein wohltuender Unfug - von der actyvyst GmbH für Euch.",
};
