import { consoleLog } from "../util/consoleLog";
import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../gameContext";
import { useRemainingTime } from "../hooks/useRemainingTime";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import RenderThumbs from "../support/renderThumbs";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

function GameStatus(props) {
  const {
    setStoryboardStep,
    setStoryboardOptions,
    setParticipantsList,
    logInAs,
    loggedIn,
    setGameExpression,
    photosDispatch,
    gameExpression,
    setGameEndTime,
    setGameId,
    setGameHost,
    setGameStartTime,
    gameHost,
    gameFinished,
    setGameFinished,
    serverTimeNow,
    loadGameById,
    gameId,
  } = useContext(GameContext);
  const { t } = useTranslation();
  const remainingTime = useRemainingTime();
  const [refreshAfterGame, setRefreshAfterGame] = useState(false);

  function endGameEarly() {
    setGameFinished(true);
    setGameEndTime(serverTimeNow());
    setStoryboardStep("finished");
    trackingEvent("game", "stopGameEarly");
  }

  useEffect(() => {
    console.log(remainingTime.remainingSeconds);
    if (remainingTime.remainingSeconds === 0 && !refreshAfterGame) {
      setRefreshAfterGame(true);
    }
    //eslint-disable-next-line
  }, [remainingTime]);
  useEffect(() => {
    if (refreshAfterGame === true) {
      loadGameById(gameId, false);
    }
    //eslint-disable-next-line
  }, [refreshAfterGame]);

  function renderButtonShootAgain() {
    if (!gameFinished)
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={shootAgain}
            style={{ width: "100%" }}
          >
            {t("gameStatus.button_shoot_again")}
          </Button>
        </Grid>
      );
  }

  function startNewGame() {
    trackingEvent("game", "startAnotherGame");
    setParticipantsList([]);
    setGameExpression("");
    photosDispatch({ type: "deleteAll" });
    setGameId("");
    setGameHost("");
    setGameStartTime(0);
    setGameEndTime(0);
    setGameFinished(false);
  }

  function shootAgain() {
    trackingEvent("game", "shootAgain");
    if (logInAs === gameHost) {
      setStoryboardStep("photohost"); // goes back to takePhoto without deleting the photo
      setStoryboardOptions([
        "login",
        "participants",
        "pickGame",
        "expression",
        "photohost",
      ]);
    } else {
      setStoryboardStep("photoParticipant"); // goes back to takePhoto without deleting the photo
      if (loggedIn) {
        setStoryboardOptions(["login", "pickGame", "photoParticipant"]);
      } else {
        setStoryboardOptions(["login", "photoParticipant"]);
      }
    }
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("gameStatus.meta_title")}
        description={t("gameStatus.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="expression"
          value={gameExpression}
          label={t("gameStatus.textfield_label")}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <RenderThumbs
        discloseParticipantPhotos={
          logInAs === gameHost || gameFinished ? true : false
        }
        gameRunning={!gameFinished}
      />
      {renderButtonShootAgain()}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            startNewGame();
          }}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {t("gameStatus.button_restart")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          id="countdown"
          value={remainingTime.text}
          label={t("gameStatus.game_duration_label")}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="gameStatus" />
      </Grid>
      {gameHost === logInAs ? (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              endGameEarly();
            }}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            {t("gameStatus.button_stop_early")}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
export default GameStatus;
