export const i18n_participants_en = {
  meta_title: "Pick participants - grimaces.net",
  meta_description:
    "Pick your friends to invite to a round of grimaces on grimaces.net",
  list_label: "Pick participants",
  button_play_game: "Play with selected friends",
  button_add_remove_friends: "Edit eMail addresses",
  briefing_headline: "How do I invite my friends to join the game?",
  briefing_text: `
  <p>Add eMail addresses of your friends or pick from those you entered before.</p>
  <p>After you took your photo and started the game we will inform them. Your friends 
  will not see your photo before they contributed their grimace.</p>
  <p>As privacy is important for us at grimaces.net we will delete all photos after 
  24 hours and will not publish any photos. It is save to invite your friends.
  </p>
  <p>Happy grimacing!</p>`,
};

export const i18n_participants_de = {
  meta_title: "Wähle Deine Mitspieler - grimaces.net",
  meta_description:
    "Wähle aus Deinen Freunden die Mitspieler für das nächste Spiel auf grimaces.net",
  list_label: "Wähle Deine Mitspieler",
  button_play_game: "Mitspieler auswählen",
  button_add_remove_friends: "eMail-Adressen bearbeiten",
  briefing_headline: "Wie lade ich meine Freunde ein?",
  briefing_text: `
    <p>Füge einfach die eMail-Adressen Deiner Freunde hinzu. Wenn Du schon andere 
    Spiele gespielt hast, kannst Du auch alle vorherigen Mitspieler automatisch hinzufügen</p>
    <p>Wenn Du Dein Foto aufgenommen hast, informieren wir Deine Freunde. 
    Die Mitspieler sehen Dein Foto erst nach dem Ende des Spiels - und auch nur 
    dann, wenn sie selber ein Bild geteilt haben.</p>
    <p>Privatsphäre ist uns wichtig bei grimaces.net. Deshalb löschen wir alle 
    Fotos 24 Stunden nach dem Spiel wieder und wir veröffentlichen auch keine Fotos 
    aus den Spielen. Es ist also sicher, Deine Freunde einzuladen.
    </p>
    <p>Fröhliches Grimasse schneiden!</p>`,
};

export const i18n_addRemoveFriends_en = {
  meta_title: "Add or remove friends - grimaces.net",
  meta_description:
    "Edit the list of friend to invite to a round of grimaces on grimaces.net",
  button_back_to_game: "Back to the game",
  label_add_friends: "Add friends to your address book...",
  button_add_email: "Add email to list",
  label_new_email: "New eMail address",
  label_email_already_listed: "Friend is already listed",
  button_loading: "loading...",
  button_add_prev: "Add all emails from previous games",
  label_delete_friends: "Or delete addresses from your address book...",
  button_delete: "Delete selected addresses",
  list_label: "Select addresses to delete",
  briefing_headline: "How can I add friends?",
  briefing_text: `
    <p>The easiest way is to add participants you played with in previous games automatically 
    as your friends. You can also just add eMail addresses one by one.</p>
    <p>Note that not all of your friends necessarily play the next game. Your friends list 
    is like your address book. You pick just a couple of them for every game.
    </p>
    <p>Happy grimacing!</p>`,
};

export const i18n_addRemoveFriends_de = {
  meta_title: "Freunde hinzufügen oder entfernen - grimaces.neet",
  meta_description:
    "Füge eMail Adressen Deiner Freunde hinzu, damit Du zu einem Spiel auf grimaces.net einladen kannst",
  button_back_to_game: "Zurück zur Teilnehmerauswahl",
  label_add_friends: "Füge Freunde Deinem Adressbuch hinzu...",
  button_add_email: "eMail Adresse hinzugügen",
  label_new_email: "Neue eMail Adresse",
  label_email_already_listed: "Freund ist schon gelistet",
  button_loading: "Lade Freunde...",
  button_add_prev: "Alle Teilnehmer früherer Spiele als Freunde speichern",
  label_delete_friends: "Oder lösche Einträge aus Deinem Adressbuch...",
  button_delete: "Ausgewählte Freunde entfernen",
  list_label: "Wähle Adressen aus, die gelöscht werden sollen",
  briefing_headline: "Wie kann ich Freunde hinzufügen?",
  briefing_text: `
      <p>Der einfachste Weg, Dein Adressbuch zu füllen, ist es alle Teilnehmer 
      bisheriger Spiele automatisch hinzuzufügen. Ansonsten kannst Du die eMail-Adressen 
      auch einzeln eingeben.</p>
      <p>Deine Freunde in dieser Liste sind nicht die Teilnehmer des nächsten Spiels. 
      Auf der nächsten Seite kannst Du aus Deinen Freunden die Teilnehmer auswählen.
      </p>
      <p>Fröhliches Grimasse schneiden!</p>`,
};
