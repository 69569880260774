export const i18n_expression_en = {
  meta_title: "Expression - grimaces.net",
  meta_description:
    "What does your grimace express? Come up with a tricky task for your friends.",
  textfield_label: "What will your face express?",
  select_label: "...or pick from favourite expressions",
  button_start_camera: "Start camera",
  button_inspiration: "Give me some inspiration",
  briefing_headline: "What is a good expression?",
  briefing_text: `
    <p>Almost anything will do - you might want to keep it short. Trust us - you will see a 
    wide variety of contributions from your friends - whatever you enter.</p>
    <p>You can also pick from our favorite list of expression - and you can tweak those expressions...</p>
    <p>Happy grimacing!</p>`,
  expressions: [
    "Ouch!! That chilli was way too HOT!!",
    "Pleeeease may I have the last bite??",
    "Do I know you?!!",
    "Get OFF my foot!!!",
    "Did you just call me an idiot?!!",
    "You must be an angel...",
    "Gosh, what a terrible smell!",
    "I can't stand this noise any longer!",
    "HA! HAHA! Excellent joke!!",
    "It's so damn cold!!",
  ],
};

export const i18n_expression_de = {
  meta_title: "Der Grimsel - grimaces.net",
  meta_description:
    "Was ist ein Grimsel? Alles, was Dein Gesicht ausdrücken mag, ist ein guter Grimsel - ein Spruch, ein Ausruck, ein Fluch. Probier es einfach aus...",
  textfield_label: "Was ist Dein Grimsel",
  select_label: "...oder wähle aus beliebten Grimseln",
  button_start_camera: "Kamera starten",
  button_inspiration: "Zeigt mir mehr Inspirationen",
  briefing_headline: "Was ist denn ein Grimsel?",
  briefing_text: `
      <p>Alles, was Dein Gesicht ausdrücken mag, ist ein guter Grimsel - ein Spruch, ein Ausruck, ein Fluch. 
      Anstößig soll es nicht sein - es soll ja Spaß machen.</p>
      <p>Fast alles funktioniert - am besten hälst Du es kurz. Vertraue uns - Du wirst die 
      kuriosesten Grimassen Deiner Freunde sehen - egal was Dein Grimsel ist.</p>
      <p>Du kannst auch aus einer Auswahl der beliebtesten Grimsel wählen - und die dann noch verändern...</p>
      <p>Fröhliches Grimassen schneiden!</p>`,
  expressions: [
    "Ufff. Die Chili war definitiv zu scharf!",
    "Darf ich bitte, bitte den letzten Biss haben?",
    "Kenn ich Dich?",
    "Ey, Du stehst auf meinem Fuß!",
    "Hast Du mich gerade Idiot genannt?",
    "Du musst ein Engel sein...",
    "Urrgh - wie das stinkt!",
    "Nicht schon wieder dieses Lied!",
    "HA! HAHA! Super witzig",
    "Brrrr - ist das kalt!",
  ],
};
