import React, { useState, useContext } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import BriefingI18n from "../support/briefingI18n";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";
// import { consoleLog } from "../util/consoleLog";

function SignUp(props) {
  const { setStoryboardStep } = useContext(GameContext);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const { t } = useTranslation();

  function handleChange(e, field) {
    if (field === 1) setPassword(e.target.value);
    else setPasswordRepeat(e.target.value);
  }

  // TODO check if user is on blacklist and remove from blacklist
  function acceptAndSignUp() {
    if (password === passwordRepeat) {
      setStoryboardStep("signUpConfirm");
      trackingEvent("account", "signup");
    } else {
      alert(t("signup.pwd_dont_match"));
    }
  }

  function toLogin() {
    setStoryboardStep("login");
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("signup.meta_title")}
        description={t("signup.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="pwd"
          type="password"
          label={t("signup.textfield1_label")}
          variant="outlined"
          style={{ width: "100%", marginBottom: "3px" }}
          onChange={(e) => handleChange(e, 1)}
        />
      </Grid>
      <Grid item xs={12}>
        <Textfield
          id="repeatpwd"
          type="password"
          label={t("signup.textfield2_label")}
          variant="outlined"
          style={{ width: "100%", marginBottom: "2px" }}
          onChange={(e) => handleChange(e, 2)}
        />
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="generic" />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={password === "" || passwordRepeat === ""}
          variant={"contained"}
          onClick={acceptAndSignUp}
          style={{ width: "100%" }}
        >
          {t("signup.button_signup")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"outlined"}
          onClick={toLogin}
          style={{ width: "100%" }}
        >
          {t("signup.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default SignUp;
