import { useContext } from "react";
import { GameContext } from "./gameContext";
import Header from "./header";
import { Prompt, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const PageContainer = (props) => {
  var { storyboardStep } = useContext(GameContext);
  const { t } = useTranslation();
  return (
    <div>
      <Prompt
        when={useLocation().pathname === "/game" ? true : false}
        message={t("generic.prompt_message")}
      />
      {storyboardStep !== "adsense" ? <Header /> : null}
      {props.children}
      <CookieConsent
        location="bottom"
        overlay={true}
        expires={1}
        buttonText={t("generic.cookie_consent_button")}
        style={{ background: "#4196f6" }}
        buttonStyle={{
          color: "#000000",
          background: "#ffd700",
          fontSize: "13px",
        }}
      >
        <Typography variant="body2" style={{ fontSize: "13px" }}>
          {t("generic.cookie_consent_message")}
        </Typography>
      </CookieConsent>
    </div>
  );
};

export default PageContainer;
