import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import BriefingI18n from "../support/briefingI18n";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function SignUpConfirm(props) {
  const { setStoryboardStep } = useContext(GameContext);
  const { t } = useTranslation();

  function toLogin() {
    setStoryboardStep("login");
  }

  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("signupConfirm.meta_title")}
        description={t("signupConfirm.meta_description")}
      />
      <Grid item xs={12}>
        <BriefingI18n briefing="signupConfirm" />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={toLogin}
          style={{ width: "100%" }}
        >
          {t("signupConfirm.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default SignUpConfirm;
