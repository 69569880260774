import React, { useContext } from "react";
import { GameContext } from "../gameContext";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";
import trackingEvent from "../util/tracking";

import Button from "@mui/material/Button";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";
import WallOfFame from "../support/wallOfFame";

function ParticipantPhotoPreview(props) {
  const {
    setStoryboardStep,
    photoProvisional,
    setPhotoProvisional,
    addOrReplacePhoto,
    gameId,
    logInAs,
    determineStoryboardStep,
  } = useContext(GameContext);
  const { t } = useTranslation();

  function contributePhoto() {
    addOrReplacePhoto(gameId, {
      user: logInAs,
      jpg: photoProvisional,
    });
    setPhotoProvisional("");
    determineStoryboardStep();
  }

  function shootAgain() {
    setStoryboardStep("photoParticipant");
    trackingEvent("game", "shootAgain");
  }
  return (
    <Grid container spacing={1}>
      <MetaDecorator
        title={t("participant_photo_preview.meta_title")}
        description={t("participant_photo_preview.meta_description")}
      />
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        {photoProvisional ? (
          <img
            src={photoProvisional}
            style={{ maxWidth: "200px" }}
            alt="Grimace"
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          onClick={() => {
            contributePhoto();
          }}
          style={{ width: "100%" }}
        >
          {t("participant_photo_preview.button_contribute")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={shootAgain}
          style={{ width: "100%" }}
        >
          {t("participant_photo_preview.button_retake")}
        </Button>
      </Grid>{" "}
      <Grid item xs={12}>
        <BriefingI18n briefing="participant_photo_preview" />
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}

export default ParticipantPhotoPreview;
