import { consoleLog } from "../util/consoleLog";
import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

import Webcam from "react-webcam";

function TakePhoto(props) {
  const {
    cameraActive,
    logInAs,
    loggedIn,
    gameId,
    participantsList,
    gameExpression,
    addOrReplacePhoto,
    setPhotoProvisional,
    gameHost,
    determineStoryboardStep,
  } = useContext(GameContext);

  const [selfTimerIn, setSelfTimerIn] = useState(-1);
  const [timerInterval, setTimerInterval] = useState(null);
  // const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  const [facingMode, setFacingMode] = React.useState("user");
  const { t } = useTranslation();

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    try {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    } catch (err) {
      consoleLog.log(err);
      trackingEvent("fatal", "exception", "mediaDevice", err);
    }
  }, [handleDevices]);

  useEffect(() => {
    // consoleLog.log(
    //   `useEffect called: selfTimerIn: ${selfTimerIn}, timerInterval: ${timerInterval}`
    // );

    if (timerInterval === null && selfTimerIn > 0) {
      const handle = setInterval(() => {
        // consoleLog.log(`Interval tick: selfTimerIn: ${selfTimerIn}`);
        setSelfTimerIn((current) => {
          return current - 1;
        });
      }, 1000);
      // consoleLog.log(`Interval created: ${handle}`);
      setTimerInterval(handle);
      trackingEvent("game", "useSelfTimer", "", selfTimerIn);
    } else if (selfTimerIn === 0) {
      // consoleLog.log(`Clear Interval Handle: ${timerInterval}`);
      setSelfTimerIn(-1);
      clearInterval(timerInterval);
      setTimerInterval(null);
      capture();
    }
    //eslint-disable-next-line
  }, [selfTimerIn, timerInterval]);

  const videoConstraints = {
    width: { min: 600, ideal: 900, max: 1200 },
    height: { min: 600, ideal: 900, max: 1200 },
    aspectRatio: 1,
    facingMode: facingMode,
  };

  const webcamRef = useRef(null);
  // devices.map((device, key) =>
  //   consoleLog.log(`Devices: ${device.deviceId},  ${device.label}`)
  // );
  // consoleLog.log(devices.length);
  const capture = useCallback(() => {
    const thisPhoto = webcamRef.current.getScreenshot();
    if (loggedIn) {
      addOrReplacePhoto(gameId, {
        user: logInAs,
        jpg: thisPhoto,
      });
    } else {
      setPhotoProvisional(thisPhoto);
    }
    determineStoryboardStep();
    // consoleLog.log(`photos.length in takePhoto: ${photos.length}`);
    trackingEvent("game", "takePhoto");
    //eslint-disable-next-line
  }, [webcamRef]);

  function showCamera() {
    if (cameraActive) {
      return (
        <Webcam
          mirrored={true}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          ref={webcamRef}
          style={{
            maxHeight: "300px",
            border: "1px solid grey",
          }}
        />
      );
    } else if (devices.length === 0) {
      return (
        <Paper
          sx={{ typography: "h5" }}
          elevation={1}
          style={{ width: "100%" }}
        >
          <div style={{ padding: "30px" }}>
            {t("takePhoto.error_no_camera")}
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper
          sx={{ typography: "h5" }}
          elevation={1}
          style={{ width: "100%" }}
        >
          <div style={{ padding: "30px" }}>
            {t("takePhoto.error_inactive_camera")}
          </div>
        </Paper>
      );
    }
  }

  function renderCameraToggle() {
    if (devices.length > 1) {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              setFacingMode(facingMode === "user" ? "environment" : "user");
            }}
            style={{ width: "100%" }}
          >
            {t("takePhoto.button_toggle_camera")}
          </Button>
        </Grid>
      );
    }
  }

  function discloseParticipants() {
    // if (gameHost !== "" && gameHost !== logInAs) {
    return (
      <Textfield
        id="participants"
        multiline
        value={`${
          gameHost === "" ? logInAs : gameHost
        } (host), ${participantsList.map((user) => user.email).join(", ")}`}
        label={t("takePhoto.disclose_participants")}
        variant="outlined"
        InputProps={{
          readOnly: true,
          style: { fontSize: "10pt" },
        }}
        style={{ width: "100%", marginTop: "10px" }}
      />
    );
    // }
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("takePhoto.meta_title")}
        description={t("takePhoto.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="expression"
          value={gameExpression}
          label={t("takePhoto.textfield_label")}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        {showCamera()}
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!cameraActive}
          variant="contained"
          onClick={capture}
          style={{ width: "100%" }}
        >
          {t("takePhoto.button_take_photo")}{" "}
          {selfTimerIn !== -1
            ? `in ${selfTimerIn} ${t("takePhoto.countdown_seconds")}`
            : null}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={!cameraActive}
          variant="outlined"
          onClick={() => {
            setSelfTimerIn(3);
          }}
          style={{ width: "100%" }}
        >
          {t("takePhoto.button_self_timer_3")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={!cameraActive}
          variant="outlined"
          onClick={() => {
            setSelfTimerIn(10);
          }}
          style={{ width: "100%" }}
        >
          {t("takePhoto.button_self_timer_10")}
        </Button>
      </Grid>
      {renderCameraToggle()}
      <Grid item xs={12}>
        {discloseParticipants()}
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n
          briefing={
            gameHost === logInAs ? "takePhoto" : "takePhoto_participant"
          }
        />
      </Grid>
    </Grid>
  );
}
export default TakePhoto;
