// import { consoleLog } from "../util/consoleLog";
import React, { useEffect, useContext, useState } from "react";
import { GameContext } from "../gameContext";
// import { useLocation, useNavigate } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation, Trans } from "react-i18next";

import WallOfFame from "../support/wallOfFame";
import { useRemainingTime } from "../hooks/useRemainingTime";

import {
  writeUserToBlackList,
  writeSetParticipantDeclined,
} from "../util/apiConnect";

// TODO: Briefing bei bad invitation

function ParticipantConsent(props) {
  const {
    setLogInAs,
    gameHost,
    participantsList,
    gameExpression,
    gameId,
    loadGameById,
    setParticipantsList,
    setGameExpression,
    photosDispatch,
    setGameHost,
    setGameStartTime,
    setGameFinished,
  } = useContext(GameContext);
  const { t } = useTranslation();
  const [gameLoaded, setGameLoaded] = useState(null);
  const [invitationValid, setInvitationValid] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState("");
  const [savingOptOut, setSavingOptOut] = useState("");
  const [savingRainCheck, setSavingRainCheck] = useState("");
  const [loading, setSetLoading] = useState(t("participant_consent.loading"));

  const remainingTime = useRemainingTime();
  // const navigate = useNavigate();
  const history = useHistory();
  const search = useLocation().search;

  useEffect(() => {
    const email = new URLSearchParams(search).get("email");
    setInvitationEmail(email);
    const thisGameId = new URLSearchParams(search).get("gameId");
    // consoleLog.log(thisGameId);
    if (thisGameId !== null) {
      if (loadGameById(thisGameId, false)) {
        setGameLoaded(true);
      } else {
        setGameLoaded(false);
      }
      setSetLoading(t("participant_consent.loaded"));
    } else {
      setSetLoading(t("participant_consent.error_loading"));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // consoleLog.log(`gameLoaded ${gameLoaded}`);
    // consoleLog.log(`gameId ${gameId}`);
    // consoleLog.log(`gameHost ${gameHost}`);
    // consoleLog.log(`participantsList ${JSON.stringify(participantsList)}`);
    // consoleLog.log(`invitationEmail ${invitationEmail}`);
    if (
      gameLoaded !== null &&
      gameHost !== null &&
      participantsList.length > 0 &&
      invitationEmail !== null
    ) {
      if (
        invitationEmail === gameHost ||
        participantsList.find((user) => user.email === invitationEmail)
      ) {
        // Spiel gefunden und geladen. Jetzt auf zum Photoshooting
        setInvitationValid(true);
        setLogInAs(invitationEmail);
        setSetLoading(t("participant_consent.loaded"));
      } else {
        setSetLoading(t("participant_consent.error_no_participant"));
      }
    } else {
      setSetLoading(t("participant_consent.error_no_game"));
    }
    //eslint-disable-next-line
  }, [gameLoaded, gameHost, participantsList, invitationEmail]);

  const writeOptOut = async (email) => {
    try {
      setSavingOptOut(t("participant_consent.saving"));
      await writeUserToBlackList({ email: email });
      setSavingOptOut(t("participant_consent.saved"));
      resetGame();
      // consoleLog.log(`email: ${email}`);
    } catch (err) {
      trackingEvent("nonFatal", "exception", "writeOptOut", err, gameId);
      setSavingOptOut("saveError");
    }
  };

  const writeRainCheck = async (gameId, email) => {
    try {
      setSavingRainCheck(t("participant_consent.saving"));
      await writeSetParticipantDeclined(gameId, email);
      // consoleLog.log(`email: ${email}`);
      setSavingRainCheck(t("participant_consent.saved"));
      resetGame();
    } catch (err) {
      trackingEvent("nonFatal", "exception", "writeRainCheck", err, gameId);
      setSavingRainCheck("saveError");
    }
  };

  function confirmConsent(e) {
    trackingEvent("game", "confirmConsent");
    // navigate("/game/");
    history.push("/game/");
  }

  function resetGame() {
    setParticipantsList([]);
    setGameExpression("");
    photosDispatch({ type: "deleteAll" });
    setGameHost("");
    setGameStartTime(0);
    setGameFinished(false);
    setLogInAs("");
    // navigate("/game/");
    history.push("/game/");
  }

  function declineInvitation() {
    trackingEvent("game", "declineInvitation");
    if (window.confirm(t("participant_consent.confirm_rain_check"))) {
      if (!writeRainCheck(gameId, invitationEmail)) {
        setSavingRainCheck("saveError");
      }
    }
  }
  function labelRainCheck() {
    if (savingRainCheck === "saving") return t("participant_consent.saving");
    else if (savingRainCheck === "saveError")
      return t("participant_consent.error_rain_check");
    else return t("participant_consent.button_rain_check");
  }

  // TODO Eigentlich braucht es ein double opt-out. Sonst kann man ein spiel aufsetzen und leute einladen und dann für sie out-opten
  function optOut() {
    trackingEvent("account", "optOut");
    if (window.confirm(t("participant_consent.confirm_opt_out"))) {
      trackingEvent("account", "optOutConfirmed");
      // consoleLog.log(`invitationEmail: ${invitationEmail}`);
      if (!writeOptOut(invitationEmail)) setSavingOptOut("saveError");
      if (!writeRainCheck(gameId, invitationEmail)) {
        setSavingRainCheck("saveError");
      }
    }
  }
  function labelOptOut() {
    if (savingOptOut === "saving") return t("participant_consent.saving");
    else if (savingOptOut === "saveError")
      return t("participant_consent.error_opt_out");
    else return t("participant_consent.button_opt_out");
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("participant_consent.meta_title")}
        description={t("participant_consent.meta_description")}
      />
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
      <Grid item xs={12}>
        <Textfield
          id="expression"
          value={
            loading === "loaded" || loading === "geladen"
              ? invitationEmail
              : loading
          } // TODO Erfolgsstatus über lokalisierte Strings ist eine ganz schlechte Idee. Mal aufräumen
          label={t("participant_consent.textfield_confirm_email")}
          variant="outlined"
          style={{ width: "100%", marginTop: "10px" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!invitationValid}
          variant="contained"
          onClick={confirmConsent}
          style={{ width: "100%" }}
        >
          {t("participant_consent.button_confirm_email")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{ typography: "body2", fontStyle: "italic" }}
          elevation={3}
          style={{ width: "100%" }}
        >
          <div style={{ padding: "20px" }}>
            <Typography sx={{ typography: "h6" }}>
              {t("participant_consent.brief_headline")}
            </Typography>
            <p></p>
            <Trans
              i18nKey="participant_consent.brief_text"
              values={{
                gameHost: gameHost,
                gameExpression: gameExpression,
                participants: participantsList
                  .map((user) => user.email)
                  .join(", "),
                remainingTime: remainingTime,
              }}
            ></Trans>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!invitationValid}
          variant="contained"
          onClick={confirmConsent}
          style={{ width: "100%" }}
        >
          {t("participant_consent.button_confirm_email")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!invitationValid}
          variant="outlined"
          onClick={declineInvitation}
          style={{ width: "100%" }}
        >
          {labelRainCheck()}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="generic" />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!invitationValid}
          variant="outlined"
          onClick={optOut}
          style={{ width: "100%", marginBottom: "10px", marginTop: "50px" }}
        >
          {labelOptOut()}
        </Button>
      </Grid>
    </Grid>
  );
}
export default ParticipantConsent;
