import { consoleLog } from "../util/consoleLog";
import React, { useEffect, useState } from "react";
import { getJournal } from "../util/apiConnect";
import Grid from "@mui/material/Grid";

function Journal() {
  const [journal, setJournal] = useState([]);

  const loadJournal = async () => {
    try {
      const dbResponse = await getJournal();
      consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
      setJournal(dbResponse);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  function renderJournal() {
    var entriesRendered = [];
    if (journal.length !== 0) {
      for (var i = 0; i < journal.length; i++) {
        entriesRendered.push(
          <React.Fragment key={journal[i].event + journal[i].createdDate}>
            <Grid item xs={2}>
              {journal[i].event}
            </Grid>
            <Grid item xs={7}>
              {JSON.stringify(journal[i].detail)}
            </Grid>
            <Grid item xs={3}>
              {new Date(journal[i].createdDate).toLocaleString()}
            </Grid>
          </React.Fragment>
        );
      }
      // consoleLog.log(subscriptionsRendered);
      return entriesRendered;
    }
  }

  useEffect(() => {
    loadJournal();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        Last 100 entries:
      </Grid>
      {renderJournal()}
    </Grid>
  );
}

export default Journal;
