// import { consoleLog } from "../util/consoleLog";
import { useState, useContext, useEffect } from "react";
import { GameContext } from "../gameContext";
import { useTranslation } from "react-i18next";

export const useRemainingTime = () => {
  const { gameStartTime, gameDuration, serverTimeNow } =
    useContext(GameContext);
  const [remainingSeconds, setRemainingSeconds] = useState(
    Math.floor((gameStartTime + gameDuration * 1000 - serverTimeNow()) / 1000)
  );
  const { t } = useTranslation();
  // consoleLog.log(`gameStartTime: ${gameStartTime}`);
  // consoleLog.log(`gameDuration: ${gameDuration}`);
  // consoleLog.log(`serverTimeNow(): ${serverTimeNow()}`);

  useEffect(() => {
    // consoleLog(`remainingSeconds: ${remainingSeconds}`);
    if (remainingSeconds > 0) {
      const timer1 = setTimeout(() => {
        setRemainingSeconds(
          Math.floor(
            (gameStartTime + gameDuration * 1000 - serverTimeNow()) / 1000
          )
        );
      }, 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
    //eslint-disable-next-line
  }, [remainingSeconds]);

  if (remainingSeconds < 1) {
    return {
      remainingSeconds: remainingSeconds,
      text: t("gameStatus.game_finished"),
    };
  }
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds - hours * 3600) / 60);
  const seconds = remainingSeconds - hours * 3600 - minutes * 60;

  return {
    remainingSeconds: remainingSeconds,
    text: `${hours === 0 ? "" : hours + " " + t("gameStatus.duration_hours")} ${
      minutes === 0 ? "" : minutes + " " + t("gameStatus.duration_minutes")
    } ${seconds === 0 ? "" : seconds + " " + t("gameStatus.duration_seconds")}`,
  };
};
