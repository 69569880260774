export const i18n_takePhoto_en = {
  meta_title: "Take your photo - grimaces.net",
  meta_description:
    "Take the photo of your grimace. Your friends will only see it after they posted their grimace. It's going to be fun!",
  textfield_label: "How do you look when you think of:",
  error_no_camera: "No camera - no grimace - no game...",
  error_inactive_camera: "Please start camera and allow access...",
  button_take_photo: "Take photo",
  button_self_timer_3: "3 seconds self-timer",
  button_self_timer_10: "10 seconds self-timer",
  countdown_take_photo: "Take photo",
  countdown_seconds: "seconds",
  button_toggle_camera: "Toggle camera",
  disclose_participants: "Game participants",
  briefing_headline: "What happens with my photo?",
  briefing_text: `
    <p>You own the photos you take from your face! At grimaces.net we take 
    privacy seriously and use the photos only for the purpose of this game.</p>
    <p>All photos are deleted 24h after the end of the game. We encourage you to 
    respect the other game participants as well and not to take screenshots or 
    copies of their photos.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_takePhoto_de = {
  meta_title: "Grimasse aufnehmen - grimaces.net",
  meta_description:
    "Mach ein Foto von Deiner Grimasse. Deine Freunde sehen das Foto erst nach Ablauf des Spiels und auch nur, wenn sie selber ein Foto geschickt haben!",
  textfield_label: "Dein Gesicht, wenn Du an diesen Grimsel denkst:",
  error_no_camera: "Keine Kamera - keine Grimasse - kein Spiel...",
  error_inactive_camera: "Bitte starte Deine Kamera bzw erlaube den Zugriff...",
  button_take_photo: "Foto aufnehmen",
  button_self_timer_3: "Selbstauslöser 3 Sekunden",
  button_self_timer_10: "Selbstauslöser 10 Sekunden",
  countdown_take_photo: "Selbstauslöser",
  countdown_seconds: "Sekunden",
  button_toggle_camera: "Kamera umschalten",
  disclose_participants: "Teilnehmer des Spiels",
  briefing_headline: "Was passiert mit meinen Fotos?",
  briefing_text: `
      <p>Die Fotos von Deinem Gesicht gehören Dir. Wir verwenden die Fotos nur für den
      Zweck dieses Spiels und teilen sie nur mit den Teilnehmern des Spiels.</p>
      <p>Alle Fotos werden 24 Stunden nach dem Spiel gelöscht. Wir bitten alle 
      Spiele das Recht der Mitspieler zu wahren und keine Screenshots und Kopien 
      der Fotos zu machen.</p>
      <p>Fröhliches Grimassen schneiden!</p>
  `,
};
