import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
// import { Routes, Route } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { GameContext } from "./components/gameContext";
import { HelmetProvider } from "react-helmet-async";
import PageContainer from "./components/pageContainer";

import Homepage from "./components/other/homepage";
import Admin from "./components/admin/admin";

import Adsense from "./components/other/adsense";
import AdTest from "./components/other/adTest";
import Login from "./components/account/login";
import LoginPwd from "./components/account/loginPwd";
import LoginPwdForgot from "./components/account/loginPwdForgot";
import SignUp from "./components/account/signUp";
import SignUpConfirm from "./components/account/signUpConfirm";
import PickGame from "./components/primary/pickGame";
import ListParticipants from "./components/primary/listParticipants";
import AddRemoveFriends from "./components/account/addRemoveFriends";
import EnterExpression from "./components/primary/enterExpression";
import TakePhoto from "./components/primary/takePhoto";
import StartGame from "./components/primary/startGame";
import ParticipantConsent from "./components/secondary/participantConsent";
import ParticipantPhotoPreview from "./components/secondary/participantPhotoPreview";
import GameStatus from "./components/primary/gameStatus";
import GameFinished from "./components/primary/gameFinished";
import PhotoGallery from "./components/primary/photoGallery";
import Terms from "./components/other/terms";
import Gdpr from "./components/other/gdpr";
import Imprint from "./components/other/imprint";
import TheWallOfFame from "./components/other/theWallOfFame";
import ParticipantNeedsSignUp from "./components/secondary/participantNeedsSignUp";
import ContributePhoto from "./components/secondary/contributePhoto";
import Subscribe from "./components/secondary/subscribe";

function App() {
  var { storyboardStep } = useContext(GameContext);

  function renderGame() {
    switch (storyboardStep) {
      case "adsense":
        return <Adsense />;
      case "loginPwd":
        return <LoginPwd />;
      case "loginPwdForgot":
        return <LoginPwdForgot />;
      case "signUp":
        return <SignUp />;
      case "signUpConfirm":
        return <SignUpConfirm />;
      case "pickGame":
        return <PickGame />;
      case "participants":
        return <ListParticipants />;
      case "friends":
        return <AddRemoveFriends />;
      case "expression":
        return <EnterExpression />;
      case "photohost":
      case "photoParticipant":
        return <TakePhoto />;
      case "start":
        return <StartGame />;
      case "partiConsent":
        return <ParticipantConsent />;
      case "participantPhotoPreview":
        return <ParticipantPhotoPreview />;
      case "photoparti1":
      case "photoparti2":
      case "status":
        return <GameStatus />;
      case "gallery":
        return <PhotoGallery />;
      case "finished":
        return <GameFinished />;
      case "terms":
        return <Terms />;
      case "gdpr":
        return <Gdpr />;
      case "imprint":
        return <Imprint />;
      case "wallOfFame":
        return <TheWallOfFame />;
      case "participantNeedsSignUp":
        return <ParticipantNeedsSignUp />;
      case "contributePhoto":
        return <ContributePhoto />;
      case "subscribe":
        return <Subscribe />;
      default:
        return <Login />;
    }
  }

  function renderInvitation() {
    return <ParticipantConsent />;
  }

  // TODO: Prompt or usePrompt to prevent unloading.
  // Problem: When trying to implement basic sample, it says that Prompt is not exported from react-router-dom

  return (
    <HelmetProvider>
      <Grid container style={{ width: "97%" }}>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            minWidth: 300,
            maxWidth: 700,
            margin: "auto",
            paddingLeft: "5px",
            paddingRight: "2px",
            paddingBottom: "5px",
          }}
        >
          {/* <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/ad" element={<Adsense />} />
            <Route path="/adtest" element={<AdTest />} />
            <Route path="/adminForRealAdminsNotEggheads" element={<Admin />} />
            <Route path="/invitation" element={renderInvitation()} />
            <Route path="/game" element={renderGame()} />
            </Routes> */}
          <Switch>
            <Route path="/ad">
              <Adsense />
            </Route>
            <Route path="/adtest">
              <AdTest />
            </Route>
            <Route path="/adminForRealAdminsNotEggheads">
              <Admin />
            </Route>
            <Route path="/invitation">
              <PageContainer>{renderInvitation()}</PageContainer>
            </Route>
            <Route path="/game">
              <PageContainer>{renderGame()} </PageContainer>
            </Route>
            <Route path="/">
              <Homepage />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </HelmetProvider>
  );
}
export default App;
