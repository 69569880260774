import React, {
  useState,
  useContext,
  useReducer,
  useRef,
  useEffect,
} from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Textfield from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import BriefingI18n from "../support/briefingI18n";
import WallOfFame from "../support/wallOfFame";
import { useTranslation } from "react-i18next";

function EnterExpression(props) {
  const { gameExpression, setGameExpression } = useContext(GameContext);
  const [expression, setExpression] = useState(gameExpression);
  const [refreshValue, triggerRefresh] = useReducer((x) => x + 1, 0);
  const { t } = useTranslation();
  var refTextInput = useRef(null);

  useEffect(() => {
    refTextInput.current.firstChild.focus();
  }, [expression]);

  function handleChange(e) {
    setExpression(e.target.value);
  }

  function renderExpression(i) {
    return (
      <MenuItem key={`expression${i}`} value={t(`expression.expressions.${i}`)}>
        {t(`expression.expressions.${i}`)}
      </MenuItem>
    );
  }

  function saveExpressionContext(e) {
    setGameExpression(expression);
    trackingEvent("game", "enterExpression");
  }

  function inspireMe() {
    trackingEvent("game", "inspireMe");
    triggerRefresh();
    // refTextInput.current.firstChild.focus();  // don't set focus. on mobile devices the keyboard pops up and overlays the changing pictures
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("expression.meta_title")}
        description={t("expression.meta_description")}
      />
      <Grid item xs={12}>
        <Textfield
          id="expression"
          ref={refTextInput}
          value={expression}
          label={t("expression.textfield_label")}
          // autoFocus
          variant="outlined"
          onChange={handleChange}
          style={{ width: "100%", marginTop: "10px", marginBottom: "5px" }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && expression) {
              saveExpressionContext();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="pickExpressionLabel">
            {t("expression.select_label")}
          </InputLabel>
          <Select
            labelId="pickExpressionLabel"
            id="pickExpression"
            value=""
            label={t("expression.select_label")}
            onChange={handleChange}
            style={{ width: "100%" }}
          >
            {renderExpression(0)}
            {renderExpression(1)}
            {renderExpression(2)}
            {renderExpression(3)}
            {renderExpression(4)}
            {renderExpression(5)}
            {renderExpression(6)}
            {renderExpression(7)}
            {renderExpression(8)}
            {renderExpression(9)}
            {/* TODO über das i18next Array kann man nicht iterieren. Aber besser als so geht es bestimmt */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!expression}
          variant="contained"
          onClick={saveExpressionContext}
          style={{ width: "100%" }}
        >
          {t("expression.button_start_camera")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BriefingI18n briefing="expression" />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => inspireMe()}
          style={{ width: "100%" }}
        >
          {t("expression.button_inspiration")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} refreshGrimaces={refreshValue} />
      </Grid>
    </Grid>
  );
}
export default EnterExpression;
