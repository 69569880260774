export const i18n_pickGame_en = {
  meta_title: "Pick a game - grimaces.net",
  meta_description:
    "Pick a game you played before or that is currently running. Or create a new game.",
  list_label: "...or load previous game:",
  list_started_on: "Started on",
  list_started_by: "by",
  button_open_game: "Open selected game",
  button_start_game: "Start a new game",
  button_game_with_prev: "Play again with same participants",
  loading: "loading...",
  loading_failed: "No previous games found. Just start a new game...",
  briefing_headline: "How long can I access the games?",
  briefing_text: `
  <p>You can create a new game any time - more grimaces are more fun.</p>
  <p>Photos are deleted 24 hours after the game is finished - after that you cannot access the game anymore.
  However you can still use it as blueprint for the next game to invite the same participants.</p>
  <p>Happy grimacing!</p>`,
};

export const i18n_pickGame_de = {
  meta_title: "Wähle ein Spiel aus - grimaces.net",
  meta_description:
    "Wähle ein Spiel, das gerade läuft, öffne ein früheres Spiel oder starte ein neues Spiel.",
  list_label: "...oder vorheriges Spiel laden:",
  list_started_on: "Gestartet am",
  list_started_by: "von",
  button_open_game: "Spiel laden",
  button_start_game: "Neues Spiel starten",
  button_game_with_prev: "Noch einmal mit gleichen Teilnehmern spielen",
  loading: "lade Spiele...",
  loading_failed:
    "Keine vorherigen Spiele gefunden. Starte einfach ein neues Spiel...",
  briefing_headline: "Wie lange kann ich auf Spiel zugreifen?",
  briefing_text: `
    <p>Neue Spiel kannst Du jederzeit erstellen - auch mehrere Spiele parallel. Je mehr Grimassen, desto mehr Spaß.</p>
    <p>Fotos löschen wir 24 Stunden nach der regulären Spielzeit. Danach kannst Du das Spiel 
    auch nicht mehr öffnen. Du kannst ältere Spiele aber immer als Vorlage für ein neues Spiel 
    nehmen - dann sind die Teilnehmer schon vor-ausgewählt.</p>
    <p>Fröhliches Grimasse schneiden!</p>`,
};
