import { consoleLog } from "./util/consoleLog";
import React, { createContext, useState, useEffect, useReducer } from "react";
import grimacesIcon from "./img/grimace_icon.svg";
import trackingEvent from "./util/tracking";
import {
  getServerTime,
  getUserByEmail,
  getFriendsListByEmail,
  getLatestGrimace,
  updateFriendsList,
  writeGame,
  getGameById,
  writePhoto,
  updateGameEndTime,
  writePhotoContribution,
} from "./util/apiConnect";

export const GameContext = createContext();
export const GameContextProvider = (props) => {
  const [serverTimeOffset, setServerTimeOffset] = useState(0);
  const [sendRealInvitations, setSendRealInvitations] = useState(true); // false = nur in ethereal
  const [loadingGeneric, setLoadingGeneric] = useState(false); // TODO wird bei DB-Aktionen gesetzt aber noch nicht ausgewertet. Im Realbetrieb mal schauen, wo das sinnvoll ist.
  const [godMode, setGodMode] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const [manipulateData, setManipulateData] = useState(true);
  const [storyboardStep, setStoryboardStep] = useState("login");
  const [storyboardOptions, setStoryboardOptions] = useState(["login"]);
  const [logInAs, setLogInAs] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [participantsList, setParticipantsList] = useState([]);
  const [gameExpression, setGameExpression] = useState("");
  const [cameraActive, setCameraActive] = useState(true);
  const [gameDuration, setGameDuration] = useState(86400);
  const [gameStartTime, setGameStartTime] = useState(0);
  const [gameEndTime, setGameEndTime] = useState(0);
  const [createGame, setCreateGame] = useState(false);
  const [gameId, setGameId] = useState("");
  const [gameHost, setGameHost] = useState("");
  const [gameFinished, setGameFinished] = useState(false);
  const [photoProvisional, setPhotoProvisional] = useState("");
  const [photoAvatar, setPhotoAvatar] = useState(grimacesIcon);
  const [photoContributed, setPhotoContributed] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [friendsList, setFriendsList] = useState([]);
  const [friendsListUpdated, setFriendsListUpdated] = useState(false);
  // const [thisGame, setThisGame] = useState({}); // wird im Spiel nicht gebraucht - nur zu debug-Zwecken exportiert

  function logContext() {
    consoleLog.log("GAME CONTEXT:");
    consoleLog.log(`serverTimeOffset: ${serverTimeOffset}`);
    consoleLog.log(`godMode: ${godMode}`);
    consoleLog.log(`storyboardStep: ${storyboardStep}`);
    consoleLog.log(`manipulateData: ${manipulateData}`);
    consoleLog.log(`sendRealInvitations: ${sendRealInvitations}`);
    consoleLog.log(`showAds: ${showAds}`);
    consoleLog.log(`logInAs: ${logInAs}`);
    consoleLog.log(`loggedIn: ${loggedIn}`);
    consoleLog.log(`friendsList: ${JSON.stringify(friendsList)}`);
    consoleLog.log(`friendsListUpdated: ${friendsListUpdated}`);
    consoleLog.log(`participantsList: ${JSON.stringify(participantsList)}`);
    consoleLog.log(`gameExpression: ${gameExpression}`);
    consoleLog.log(`photos of users: ${photos.map((photo) => photo.user)}`);
    // consoleLog.log(`photos: ${photos}`);
    // consoleLog.log(`photos stringified: ${JSON.stringify(photos)}`);
    // consoleLog.log(`photos.length: ${photos.length}`);
    consoleLog.log(`gameDuration: ${gameDuration}`);
    consoleLog.log(`gameStartTime: ${gameStartTime}`);
    consoleLog.log(`gameEndTime: ${gameEndTime}`);
    consoleLog.log(`gameId: ${gameId}`);
    consoleLog.log(`gameHost: ${gameHost}`);
    consoleLog.log(`gameFinished: ${gameFinished}`);
    // consoleLog.log(`thisGame ${thisGame}`); // wird im Spiel nicht gebraucht - nur zum Speichern in die DB - nur zu debug-Zwecken exportiert
  }

  const photosReducer = (state, action) => {
    switch (action.type) {
      case "setPhotos":
        return action.item;
      case "addOrReplace":
        // consoleLog.log(`state in Reducer addOrReplace: ${JSON.stringify(state)}`);
        // consoleLog.log(
        //   `state.length in Reducer addOrReplace: ${JSON.stringify(
        //     state.length
        //   )}`
        // );
        const newPhoto = action.item;
        setPhotoAvatar(newPhoto.jpg);
        const photoIndex = state.findIndex(
          (photo) => photo.user === newPhoto.user
        );
        // consoleLog.log(`index ${photoIndex} `);
        if (photoIndex !== -1) {
          // User hat schon ein Bild - photo überschreiben
          state[photoIndex] = newPhoto;
          return state;
        } else {
          // Bild hinzufügen
          return [...state, action.item];
        }
      case "deleteAll":
        return []; // nur für Debug-Zwecke lokal. Wird nicht in DB geschrieben
      default:
        trackingEvent(
          "nonFatal",
          "exception",
          "photosReducer",
          "unknown action.type"
        );
        throw new Error();
    }
  };
  const [photos, photosDispatch] = useReducer(photosReducer, []);

  const addOrReplacePhoto = async (gameId, newPhoto) => {
    photosDispatch({
      type: "addOrReplace",
      item: newPhoto,
    });
    if (photos.length !== 0) {
      // first picture is host picture which is written on gameStart
      // consoleLog.log("write photo");
      try {
        setLoadingGeneric(true);
        await writePhoto(gameId, newPhoto);
        return true;
      } catch (err) {
        alert(
          "Something went wrong saving your photo. We will look into this. Please try again later to load the game and take your photo."
        );
        trackingEvent("fatal", "exception", "addOrReplacePhoto", err, gameId);
        consoleLog.log(err);
        return false;
      } finally {
        setLoadingGeneric(false);
      }
    }
  };

  const contributePhoto = async (gameId, email) => {
    trackingEvent("other", "contributePhoto");
    try {
      await writePhotoContribution(gameId, email); // write to DB
      setPhotoContributed(true);
    } catch (err) {
      trackingEvent("nonFatal", "exception", "contributePhoto", err, gameId);
      consoleLog.log(err);
    } finally {
      determineStoryboardStep();
    }
  };

  // TODO: storybook - komponenten test

  const loadGameById = async (id, onlyParticpants) => {
    try {
      setLoadingGeneric(true);
      // consoleLog.log(`loadGameById parameter id: ${id}`);
      const gameData = await getGameById(id);
      // consoleLog.log(`gameData ${JSON.stringify(gameData[0])}`);
      // consoleLog.log(`gameData.gameId ${JSON.stringify(gameData[0].gameId)}`);
      if (gameData.length === 0) {
        throw new Error(`no game found for gameId ${id}`);
      } else {
        setParticipantsList(gameData[0].participantsList);
        if (!onlyParticpants) {
          await photosDispatch({ type: "setPhotos", item: gameData[0].photos }); // TODO: Kann ich dispatch eigentlich awaiten? Einen Fehler gibt es jedenfalls nicht. Und wenn ich das nicht mache und die Photos erst am Ende der Liste schreibe, dann wird determinestoryboardstep zu oft mit falscher foto-info aufgerufen
          setGameId(gameData[0].gameId);
          setGameExpression(gameData[0].gameExpression);
          setGameHost(gameData[0].gameHost);
          setGameStartTime(gameData[0].gameStartTime);
          setGameEndTime(gameData[0].gameEndTime);
          setGameDuration(gameData[0].gameDuration);

          if (
            gameData[0].gameEndTime < Date.now() &&
            gameData[0].gameEndTime !== 0
          ) {
            setGameFinished(true);
          }
        }
      }
      // consoleLog.log(`gameData[0].gameId: ${gameData[0].gameId}`);
      // consoleLog.log(`gameData[0].gameHost: ${gameData[0].gameHost}`);
      // consoleLog.log(`gameData[0].gameExpression: ${gameData[0].gameExpression}`);
      // consoleLog.log(`gameData[0].gameStartTime: ${gameData[0].gameStartTime}`);
      // consoleLog.log(`gameData[0].gameEndTime: ${gameData[0].gameEndTime}`);
      // consoleLog.log(`gameData[0].gameDuration: ${gameData[0].gameDuration}`);
      //   consoleLog.log(
      //     `gameData[0].participantsList: ${gameData[0].participantsList}`
      //   );
      // consoleLog.log(`gameData[0].photos: ${gameData[0].photos}`);
      return true;
    } catch (err) {
      consoleLog.log(err);
      trackingEvent("nonFatal", "exception", "loadGameById", err, gameId);
      return false;
    } finally {
      setLoadingGeneric(false);
    }
  };

  const logIn = async (pin) => {
    //TODO Hier muss authentification hin. Erst mal nur: Wer in der DB ist, darf rein
    //TODO Login lokal speichern - zumindest für eine session-Dauer
    const fetchUserByEmail = async (user) => {
      try {
        setLoadingGeneric(true);
        // consoleLog.log(`user: ${JSON.stringify(user)}`);
        const userData = await getUserByEmail(user);
        // consoleLog.log(`user: ${userData[0].email}`);
        // consoleLog.log(`godMode: ${userData[0].godMode}`);
        // consoleLog.log(`userData.length: ${userData.length}`);
        if (userData.length === 0) {
          alert(`User/Passwort nicht bekannt`);
          setGodMode(false);
          consoleLog.setAllowLogging(false);
          setLoggedIn(false);
          return false;
        }
        setGodMode(userData[0].godMode);
        consoleLog.setAllowLogging(userData[0].godMode);
        setLoggedIn(true); //TODO  Hier muss authentification hin. Erst mal nur: Wer in der DB ist, darf rein
        trackingEvent("account", "login");
        return true;
      } catch (err) {
        // consoleLog.log(`err: ${err}`);
        trackingEvent("fatal", "exception", "logIn", err);
        alert(`Unable to log in at this time. Please try again later.`);
        setGodMode(false);
        consoleLog.setAllowLogging(false);
        setLoggedIn(false);
        return false;
      } finally {
        setLoadingGeneric(false);
      }
    };
    const fetchFriendsListByEmail = async (email) => {
      try {
        setLoadingGeneric(true);
        const friendsListData = await getFriendsListByEmail(email);
        // consoleLog.log(
        //   `friendsListData[0].friendsList: ${friendsListData[0].friendsList}`
        // );
        if (friendsListData.length === 0) {
          setFriendsList([]);
        } else {
          setFriendsList(friendsListData[0].friendsList);
        }
      } catch (err) {
        trackingEvent("nonFatal", "exception", "fetchFriendsListByEmail", err);
      } finally {
        setLoadingGeneric(false);
      }
    };
    const fetchLatestGrimace = async (email) => {
      try {
        setLoadingGeneric(true);
        const latestGrimace = await getLatestGrimace(email);
        // consoleLog.log(
        //   `latestGrimace[0].jpg: ${latestGrimace[0].jpg}`
        // );
        if (latestGrimace.length !== 0) {
          setPhotoAvatar(latestGrimace[0].jpg);
        }
      } catch (err) {
        trackingEvent("nonFatal", "exception", "fetchLatestGrimace", err);
      } finally {
        setLoadingGeneric(false);
      }
    };
    if (logInAs !== "") {
      const user = { email: logInAs, pin: pin };
      // consoleLog.log(`user: ${user}`);
      const loginOk = await fetchUserByEmail(user);
      // consoleLog.log(`loginOk: ${loginOk}`);
      if (loginOk) {
        await fetchFriendsListByEmail(logInAs);
        await fetchLatestGrimace(logInAs);
        // TODO check for gameId in URL, load the game and jump right in
      } else {
        setLoggedIn(false);
      }
    }
  };

  function logOut() {
    setLogInAs("");
    setLoggedIn(false);
    setPhotoAvatar(grimacesIcon);
    setFriendsList([]);
    setParticipantsList([]);
    setGameExpression("");
    setGameHost("");
    setGameId("");
    setGameStartTime(0);
    setGameEndTime(0);
    setGameFinished(false);
    photosDispatch({ type: "deleteAll" });

    trackingEvent("account", "logout");
  }

  useEffect(() => {
    determineStoryboardStep();
    //eslint-disable-next-line
  }, [
    loggedIn,
    participantsList,
    gameExpression,
    photos,
    photoProvisional,
    gameId,
    gameFinished,
  ]);

  useEffect(() => {
    if (godMode === false) {
      setManipulateData(false);
      setCameraActive(true);
    }
  }, [godMode]);

  useEffect(() => {
    const fetchFriendsListByEmail = async (email) => {
      try {
        setLoadingGeneric(true);
        const friendsListData = await getFriendsListByEmail(email);
        // consoleLog.log(
        //         `friendsListData[0].friendsList: ${friendsListData[0].friendsList}`
        //       );
        if (friendsListData.length !== 0) {
          setFriendsList(friendsListData[0].friendsList);
        } else {
          setFriendsList([]);
        }
      } catch (err) {
        trackingEvent("nonFatal", "exception", "fetchFriendsListByEmail", err);
      } finally {
        setLoadingGeneric(false);
      }
    };

    if (loggedIn && logInAs !== "") {
      fetchFriendsListByEmail(logInAs);
    }
  }, [loggedIn, logInAs]);

  useEffect(() => {
    const writeFriendsList = async (email, friendsList) => {
      try {
        setLoadingGeneric(true);
        await updateFriendsList(email, friendsList);
        // consoleLog.log(`friendsListData: ${friendsListData}`);
      } catch (err) {
        alert(
          "Something went wrong saving your friends list. You can still play the game including the newly added friends but next time you log in you will have to re-enter their addresses. We will look into this."
        );
        trackingEvent("nonFatal", "exception", "writeFriendsList", err);
      } finally {
        setLoadingGeneric(false);
      }
    };
    if (friendsListUpdated) {
      writeFriendsList(logInAs, friendsList);
      setFriendsListUpdated(false);
    }
    //eslint-disable-next-line
  }, [friendsListUpdated]);

  useEffect(() => {
    // consoleLog.log("add a game");
    const addGame = async () => {
      const tempGame = {
        gameId: gameId,
        gameHost: gameHost,
        participantsList: participantsList,
        gameExpression: gameExpression,
        gameDuration: gameDuration,
        gameStartTime: gameStartTime,
        photos: photos,
      };
      // setThisGame(tempGame); // wird im Spiel nicht gebraucht - nur zu debug-Zwecken exportiert
      try {
        setLoadingGeneric(true);
        await writeGame(tempGame, sendRealInvitations);
      } catch (err) {
        consoleLog.log(err);
        alert(
          "Something went wrong saving your game - without a saved game there are no invitations to others and no game at all. We are very sorry and will look into this. Please excuse and try again another time."
        );
        trackingEvent("fatal", "exception", "addGame", err, gameId);
      } finally {
        setLoadingGeneric(false);
      }
    };
    if (createGame) {
      consoleLog.log("los");
      // logContext();
      if (gameStartTime !== 0 && gameHost !== "") {
        addGame(); // comment this line for not creating new games in DB
        setCreateGame(false);
      }
    }
    //eslint-disable-next-line
  }, [createGame, gameStartTime, gameHost]); // reagiert auf alle Variablen, die bei gameStart gesetzt werden

  useEffect(() => {
    const finishGame = async () => {
      try {
        setLoadingGeneric(true);
        await updateGameEndTime(gameId, { gameEndTime: gameEndTime });
      } catch (err) {
        consoleLog.log(err);
        alert(
          "Something went wrong saving the games end. If you tried to end it early, please try again later. We will look into this."
        );
        trackingEvent("fatal", "exception", "finishGame", err, gameId);
      } finally {
        setLoadingGeneric(false);
      }
    };
    if (gameEndTime !== 0) {
      consoleLog.log("write gameEndTime");
      finishGame();
    }
    //eslint-disable-next-line
  }, [gameEndTime]);

  useEffect(() => {
    const evalServerTimeOffset = async () => {
      try {
        const offset = (await getServerTime()) - Date.now();
        consoleLog.log(`serverTimeOffset: ${offset}`);
        setServerTimeOffset(offset);
      } catch (err) {
        consoleLog.log(err);
        trackingEvent("nonFatal", "exception", "evalServerTimeOffset", err);
      }
    };
    evalServerTimeOffset();

    const godModeDefault =
      process.env.REACT_APP_GOD_MODE_DEFAULT === "true" ? true : false;
    // consoleLog.log({ godModeDefault });
    setGodMode(godModeDefault);
    //eslint-disable-next-line
  }, []);

  function serverTimeNow() {
    return Date.now() + serverTimeOffset;
  }

  function determineStoryboardStep() {
    // consoleLog.log(`photos: ${JSON.stringify(photos)}`);
    // consoleLog.log(`photos.length in determineStoryboard: ${photos.length}`);
    // consoleLog.log(`photos: ${JSON.stringify(photos)}`);
    // consoleLog.log(logInAs);
    // consoleLog.log(photos.find((photo) => photo.user === logInAs) === undefined);

    if (
      // user is not logged in but in participantList? Then he was invited to take a photo
      loggedIn === false &&
      participantsList.find((user) => user.email === logInAs) !== undefined
    ) {
      if (photoProvisional !== "") {
        // photo taken but not yet added to game
        setStoryboardStep("participantPhotoPreview");
        setStoryboardOptions(["login", "photoParticipant"]);
      } else if (photos.find((photo) => photo.user === logInAs) === undefined) {
        // Invited but no photo take yet
        setStoryboardStep("photoParticipant");
        setStoryboardOptions(["login, photoParticipant"]);
      } else {
        // invited and photo taken but needs to signup/login to get to status or results
        setStoryboardStep("participantNeedsSignUp");
        setStoryboardOptions(["login, participantNeedsSignUp"]);
      }
    } else if (loggedIn === false) {
      setStoryboardStep("login"); // Login
      setStoryboardOptions(["login"]);
    } else if (gameFinished) {
      setStoryboardStep("finished"); // finished
      setStoryboardOptions(["login", "finished", "contributePhoto"]);
    } else if (gameId === "") {
      setStoryboardStep("pickGame"); // pickParticipants
      setStoryboardOptions(["login", "pickGame"]);
    } else if (
      participantsList === undefined ||
      participantsList.length === 0
    ) {
      setStoryboardStep("participants"); // pickParticipants
      setStoryboardOptions(["login", "pickGame", "participants"]);
    } else if (gameExpression === "") {
      setStoryboardStep("expression"); // enterExpression
      setStoryboardOptions(["login", "pickGame", "participants", "expression"]);
    } else if (
      photos === undefined ||
      photos.length === 0 ||
      photos.find((photo) => photo.user === logInAs) === undefined
    ) {
      setStoryboardStep("photohost"); // takePhoto
      setStoryboardOptions([
        "login",
        "pickGame",
        "participants",
        "expression",
        "photohost",
      ]);
    } else if (gameStartTime === 0) {
      setStoryboardStep("start"); // startGame
      setStoryboardOptions([
        "login",
        "participants",
        "expression",
        "photohost",
        "start",
      ]);
    } else if (gameFinished) {
      setStoryboardStep("finished"); // finished
      setStoryboardOptions([
        "login",
        "finished",
        "pickGame",
        "contributePhoto",
      ]);
    } else {
      setStoryboardStep("status"); // gameStatus
      setStoryboardOptions(["reloadGame", "login", "pickGame", "status"]);
    }
  }

  return (
    <GameContext.Provider
      value={{
        cameraActive,
        friendsList,
        gameDuration,
        gameEndTime,
        gameExpression,
        gameFinished,
        gameHost,
        gameId,
        gameStartTime,
        godMode,
        loadingGeneric,
        logInAs,
        loggedIn,
        manipulateData,
        participantsList,
        photoAvatar,
        photoContributed,
        photoProvisional,
        photos,
        sendRealInvitations,
        showAds,
        storyboardOptions,
        storyboardStep,
        subscribed,
        addOrReplacePhoto,
        contributePhoto,
        determineStoryboardStep,
        loadGameById,
        logContext,
        logIn,
        logOut,
        photosDispatch,
        serverTimeNow,
        setCameraActive,
        setCreateGame,
        setFriendsList,
        setFriendsListUpdated,
        setGameDuration,
        setGameEndTime,
        setGameExpression,
        setGameFinished,
        setGameHost,
        setGameId,
        setGameStartTime,
        setGodMode,
        setLoadingGeneric,
        setLogInAs,
        setLoggedIn,
        setManipulateData,
        setParticipantsList,
        setPhotoProvisional,
        setSendRealInvitations,
        setShowAds,
        setStoryboardOptions,
        setStoryboardStep,
        setSubscribed,
        // thisGame, // wird im Spiel nicht gebraucht - nur zum Speichern in die DB - nur zu debug-Zwecken exportiert
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
};
