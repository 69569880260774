export const GRIMACES = [
  {
    jpg: "adrian-swancar-roCfgvkBLVY-unsplash_300x300.jpg",
    photographer: "adrian swancar",
  },
  {
    jpg: "adrian-swancar-_2NHZjSMLjA-unsplash_300x300.jpg",
    photographer: "adrian swancar",
  },
  {
    jpg: "afif-kusuma-F3dFVKj6q8I-unsplash_300x300.jpg",
    photographer: "afif kusuma",
  },
  {
    jpg: "afif-kusuma-ryNmjBolkh0-unsplash_300x300.jpg",
    photographer: "afif kusuma",
  },
  {
    jpg: "afif-kusuma-txzpx9doVWs-unsplash_300x300.jpg",
    photographer: "afif kusuma",
  },
  {
    jpg: "aiony-haust-f2ar0ltTvaI-unsplash_300x300.jpg",
    photographer: "aiony haust",
  },
  {
    jpg: "alessandro-bellone-sb26HOlo0W0-unsplash_300x300.jpg",
    photographer: "alessandro bellone",
  },
  {
    jpg: "alexander-krivitskiy-00GtxQYbI1I-unsplash_300x300.jpg",
    photographer: "alexander krivitskiy",
  },
  {
    jpg: "alexander-krivitskiy-bza-TEcrXfw-unsplash_300x300.jpg",
    photographer: "alexander krivitskiy",
  },
  {
    jpg: "alexander-krivitskiy-dHVffTPiU-I-unsplash_300x300.jpg",
    photographer: "alexander krivitskiy",
  },
  {
    jpg: "alexander-krivitskiy-TD9rSZywT7Y-unsplash_300x300.jpg",
    photographer: "alexander krivitskiy",
  },
  {
    jpg: "anastasia-vityukova-QhKX67yT7wk-unsplash_300x300.jpg",
    photographer: "anastasia vityukova",
  },
  {
    jpg: "andrei-caliman-GJLtBwtAwNo-unsplash_300x300.jpg",
    photographer: "andrei caliman",
  },
  {
    jpg: "ayo-ogunseinde-sibVwORYqs0-unsplash_300x300.jpg",
    photographer: "ayo ogunseinde",
  },
  {
    jpg: "ben-white-qDY9ahp0Mto-unsplash_300x300.jpg",
    photographer: "ben white",
  },
  {
    jpg: "christopher-campbell-rDEOVtE7vOs-unsplash_300x300.jpg",
    photographer: "christopher campbell",
  },
  {
    jpg: "claudia-wolff-owBcefxgrIE-unsplash_300x300.jpg",
    photographer: "claudia wolff",
  },
  {
    jpg: "damir-spanic-_7Jepp-q0cY-unsplash_300x300.jpg",
    photographer: "damir spanic",
  },
  {
    jpg: "daniil-onischenko-_J2fMaieLSM-unsplash_300x300.jpg",
    photographer: "daniil onischenko",
  },
  {
    jpg: "david-hinkle-kZbLcsYHJE0-unsplash_300x300.jpg",
    photographer: "david hinkle",
  },
  {
    jpg: "david-siglin-UuW4psOb388-unsplash_300x300.jpg",
    photographer: "david siglin",
  },
  {
    jpg: "denis-agati--5Vl9oimYlU-unsplash_300x300.jpg",
    photographer: "denis agati",
  },
  {
    jpg: "diana-polekhina-VrIZIPHwq_E-unsplash_300x300.jpg",
    photographer: "diana polekhina",
  },
  {
    jpg: "dogukan-sahin-Wddj9jJFb6k-unsplash_300x300.jpg",
    photographer: "dogukan sahin",
  },
  {
    jpg: "dorrell-tibbs-HSY1UqOyN5k-unsplash_300x300.jpg",
    photographer: "dorrell tibbs",
  },
  {
    jpg: "dylan-nolte-NGRgFc3Zky4-unsplash_300x300.jpg",
    photographer: "dylan nolte",
  },
  {
    jpg: "ekoate-nwaforlor-XLRivrl9mNI-unsplash_300x300.jpg",
    photographer: "ekoate nwaforlor",
  },
  {
    jpg: "elisa-ventur-bmJAXAz6ads-unsplash_300x300.jpg",
    photographer: "elisa ventur",
  },
  {
    jpg: "engin-akyurt-AjMXxHwxW_k-unsplash_300x300.jpg",
    photographer: "engin akyurt",
  },
  {
    jpg: "engin-akyurt-E29Wcsqqz9Q-unsplash_300x300.jpg",
    photographer: "engin akyurt",
  },
  {
    jpg: "engin-akyurt-vM6qH9bX8Bo-unsplash_300x300.jpg",
    photographer: "engin akyurt",
  },
  {
    jpg: "gideon-hezekiah-4c-wxrZMFsY-unsplash_300x300.jpg",
    photographer: "gideon hezekiah",
  },
  {
    jpg: "henrikke-due-pVsi_lmyvL8-unsplash_300x300.jpg",
    photographer: "henrikke due",
  },
  {
    jpg: "jakayla-toney-c2R04b1xqrQ-unsplash_300x300.jpg",
    photographer: "jakayla toney",
  },
  {
    jpg: "jakayla-toney-zyWb4gJGJVM-unsplash_300x300.jpg",
    photographer: "jakayla toney",
  },
  {
    jpg: "jason-yoder-C0jcu0zz1Kw-unsplash_300x300.jpg",
    photographer: "jason yoder",
  },
  {
    jpg: "jeshoots-com--2vD8lIhdnw-unsplash_300x300.jpg",
    photographer: "jeshoots com",
  },
  {
    jpg: "jonatas-domingos-CPyQASZTahE-unsplash_300x300.jpg",
    photographer: "jonatas domingos",
  },
  {
    jpg: "karina-tess-E3JD1q3HqH4-unsplash_300x300.jpg",
    photographer: "karina tess",
  },
  { jpg: "kat-j-7I1wrtRz5QQ-unsplash_300x300.jpg", photographer: "kat j" },
  { jpg: "kat-j-NPmR0RblyhQ-unsplash_300x300.jpg", photographer: "kat j" },
  {
    jpg: "kat-love-zncUDOR-Ie8-unsplash_300x300.jpg",
    photographer: "kat love",
  },
  {
    jpg: "krakenimages-8RXmc8pLX_I-unsplash_300x300.jpg",
    photographer: "krakenimages",
  },
  {
    jpg: "laura-adai--DXu0UrtCDc-unsplash_300x300.jpg",
    photographer: "laura adai",
  },
  {
    jpg: "laura-adai-AF50O2MtgOU-unsplash_300x300.jpg",
    photographer: "laura adai",
  },
  {
    jpg: "ludovic-migneault-B9YbNbaemMI-unsplash_300x300.jpg",
    photographer: "ludovic migneault",
  },
  {
    jpg: "matt-sings-ixmVe2qr8TM-unsplash_300x300.jpg",
    photographer: "matt sings",
  },
  {
    jpg: "michael-dam-mEZ3PoFGs_k-unsplash_300x300.jpg",
    photographer: "michael dam",
  },
  {
    jpg: "nathan-dumlao-rWJ2RthM-gc-unsplash_300x300.jpg",
    photographer: "nathan dumlao",
  },
  {
    jpg: "nathan-dumlao-UNnwm7kgN_0-unsplash_300x300.jpg",
    photographer: "nathan dumlao",
  },
  {
    jpg: "niklas-hamann-Pe4gh8a8mBY-unsplash_300x300.jpg",
    photographer: "niklas hamann",
  },
  {
    jpg: "nimi-diffa-syXiXAVLUbU-unsplash_300x300.jpg",
    photographer: "nimi diffa",
  },
  {
    jpg: "oliver-ragfelt-s5zc3NyWVRI-unsplash_300x300.jpg",
    photographer: "oliver ragfelt",
  },
  {
    jpg: "patrick-fore-Qz_QW-_1CzA-unsplash_300x300.jpg",
    photographer: "patrick fore",
  },
  {
    jpg: "raamin-ka-06-k8DZFJ3w-unsplash_300x300.jpg",
    photographer: "raamin ka",
  },
  {
    jpg: "raamin-ka-UqcONcb77PI-unsplash_300x300.jpg",
    photographer: "raamin ka",
  },
  {
    jpg: "raamin-ka-V09fJrQ4Rqk-unsplash_300x300.jpg",
    photographer: "raamin ka",
  },
  {
    jpg: "raamin-ka-v8dc2XkhIfM-unsplash_300x300.jpg",
    photographer: "raamin ka",
  },
  {
    jpg: "rendy-novantino-jXs9F9N44MI-unsplash_300x300.jpg",
    photographer: "rendy novantino",
  },
  {
    jpg: "repentand-seekchristjesus-y0I85D5QKvs-unsplash_300x300.jpg",
    photographer: "repentand seekchristjesus",
  },
  {
    jpg: "rod-long-QLaihFDwRCU-unsplash_300x300.jpg",
    photographer: "rod long",
  },
  {
    jpg: "ryan-franco-XECZHb6NoFo-unsplash_300x300.jpg",
    photographer: "ryan franco",
  },
  {
    jpg: "salha-frija-cdU2EJ5uDO4-unsplash_300x300.jpg",
    photographer: "salha frija",
  },
  {
    jpg: "sammy-williams-7rE4GFo0E9A-unsplash_300x300.jpg",
    photographer: "sammy williams",
  },
  {
    jpg: "sammy-williams-DIBwWsoshGE-unsplash_300x300.jpg",
    photographer: "sammy williams",
  },
  {
    jpg: "spencer-backman-1KdD2iBlnRI-unsplash_300x300.jpg",
    photographer: "spencer backman",
  },
  {
    jpg: "tasos-mansour-o_7J4koKmnM-unsplash_300x300.jpg",
    photographer: "tasos mansour",
  },
  {
    jpg: "thomas-park-KYEeuGUkZ54-unsplash_300x300.jpg",
    photographer: "thomas park",
  },
  {
    jpg: "timothy-dykes-yd4ubMUNTG0-unsplash_300x300.jpg",
    photographer: "timothy dykes",
  },
  {
    jpg: "toa-heftiba-_6P1wd1vyg4-unsplash_300x300.jpg",
    photographer: "toa heftiba",
  },
  {
    jpg: "tom-morel-hkbQnC7FAqU-unsplash_300x300.jpg",
    photographer: "tom morel",
  },
  {
    jpg: "tycho-atsma-FkbZS4dLh6U-unsplash_300x300.jpg",
    photographer: "tycho atsma",
  },
  {
    jpg: "usman-yousaf-8dvyPDYa35Q-unsplash_300x300.jpg",
    photographer: "usman yousaf",
  },
  { jpg: "wooozxh-4Cjn0FDEud8-unsplash_300x300.jpg", photographer: "wooozxh" },
  {
    jpg: "xavi-cabrera-_-uN7DbAE-o-unsplash_300x300.jpg",
    photographer: "xavi cabrera",
  },
  {
    jpg: "yogendra-singh-BxHnbYyNfTg-unsplash_300x300.jpg",
    photographer: "yogendra singh",
  },
  {
    jpg: "artem-beliaikin-TZoxR0lKzNI-unsplash_300x300.jpg",
    photographer: "artem beliaikin",
  },
  {
    jpg: "artem-beliaikin-XO25cX2_0iE-unsplash_300x300.jpg",
    photographer: "artem beliaikin",
  },
  {
    jpg: "brooke-cagle-BDIKHnBMFRQ-unsplash_300x300.jpg",
    photographer: "brooke cagle",
  },
  {
    jpg: "brut-carniollus-jGEsapFCLgw-unsplash_300x300.jpg",
    photographer: "brut carniollus",
  },
  {
    jpg: "gemma-chua-tran-UIZYDvmQg6U-unsplash_300x300.jpg",
    photographer: "gemma chua",
  },
  {
    jpg: "ilya-mirnyy-y-WSiwQgJb8-unsplash_300x300.jpg",
    photographer: "ilya mirnyy",
  },
  {
    jpg: "ivan-lapyrin-m9EIqvSp08E-unsplash_300x300.jpg",
    photographer: "ivan lapyrin",
  },
  {
    jpg: "jamie-brown-wm4DuvIpLj8-unsplash_300x300.jpg",
    photographer: "jamie brown",
  },
  {
    jpg: "jonathan-borba-TRYuCpN6jdc-unsplash_300x300.jpg",
    photographer: "jonathan borba",
  },
  {
    jpg: "ludovic-migneault-5xbCj_VWKcs-unsplash_300x300.jpg",
    photographer: "ludovic migneault",
  },
  {
    jpg: "ludovic-migneault-ZJEOe8gKyIM-unsplash_300x300.jpg",
    photographer: "ludovic migneault",
  },
  {
    jpg: "maria-lysenko-5miAc099lMc-unsplash_300x300.jpg",
    photographer: "maria lysenko",
  },
  {
    jpg: "molnar-balint-NW61v3xF0-0-unsplash_300x300.jpg",
    photographer: "molnar balint",
  },
  {
    jpg: "mor-shani-fTbmdlTfSJk-unsplash_300x300.jpg",
    photographer: "mor shani",
  },
  {
    jpg: "nicholas-kusuma-xb2DaEeX8jk-unsplash_300x300.jpg",
    photographer: "nicholas kusuma",
  },
  {
    jpg: "tabitha-turner-Y4wpkK_JWWk-unsplash_300x300.jpg",
    photographer: "tabitha turner",
  },
  {
    jpg: "taras-chernus-8CEKQlmnf50-unsplash_300x300.jpg",
    photographer: "taras chernus",
  },
  {
    jpg: "viktor-bystrov-lh8ixHx94YQ-unsplash_300x300.jpg",
    photographer: "viktor bystrov",
  },
  {
    jpg: "xenia-bogarova-xw5rXJ8oZuM-unsplash_300x300.jpg",
    photographer: "xenia bogarova",
  },
];

export const CREDITS =
  "ospan ali, adrian swancar, afif kusuma, aiony haust, alessandro bellone, alexander krivitskiy, anastasia vityukova, andrei caliman, ayo ogunseinde, ben white, christopher campbell, claudia wolff, damir spanic, daniil onischenko, david hinkle, david siglin, denis agati, diana polekhina, dogukan sahin, dorrell tibbs, dylan nolte, ekoate nwaforlor, elisa ventur, engin akyurt, gideon hezekiah, henrikke due, jakayla toney, jason yoder, jeshoots com, jonatas domingos, karina tess, kat j, kat love, krakenimages, laura adai, ludovic migneault, matt sings, michael dam, nathan dumlao, niklas hamann, nimi diffa, oliver ragfelt, patrick fore, raamin ka, rendy novantino, repentand seekchristjesus, rod long, ryan franco, salha frija, sammy williams, spencer backman, tasos mansour, thomas park, timothy dykes, toa heftiba, tom morel, tycho atsma, usman yousaf, wooozxh, xavi cabrera, yogendra singh, yogendra singh,artem beliaikin,brooke cagle,brut carniollus,gemma chua,ilya mirnyy,ivan lapyrin,jamie brown,jonathan borba,maria lysenko,molnar balint,mor shani,nicholas kusuma,tabitha turner,taras chernus,viktor bystrov,xenia bogarova";
