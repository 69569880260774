export const i18n_takePhoto_participant_en = {
  briefing_headline: "Thanks for the invitation. What happens now?",
  briefing_text: `
    <p>The game host came up with the expression above and took a photo expressing it.
    Now it is your turn to contribute a weird face! When all participants 
    contributed or the game ends, you will see the result. 
    Only players who contributed their photo will see the other participants faces.</p>
    <p>You own the photos you take from your face! At grimaces.net we
    take privacy seriously and use the photos only for the purpose of
    this game. Below you see a list of all participants of the game.</p>
    <p>All photos are deleted 24h after the end of the game. We encourage
    you to respect the other game participants as well and not to take
    screenshots or copies of their photos.</p>
    <p>Happy grimacing!</p>
`,
};

export const i18n_takePhoto_participant_de = {
  briefing_headline: "Danke für die Einladung. Wie geht's weiter?",
  briefing_text: `
  <p>Der Gastgeber hat sich den Spruch oben ausgedacht - wir nennen das den Grimsel.
  Jetzt ist es an Dir die passende Grimasse dazu zu schneiden und Dein Foto aufzunehmen.</p>
  <p>Wichtig ist, dass Du nur Dein eigenes Gesicht aufnimmst und keine Rechte Dritter oder 
  den guten Ton verletzt - schließlich ist grimaces.net ein Spaß</p>

  <p>Die Bilder werden nur mit den Mitspielern oben geteilt, die selber ein Grimasse 
  aufgenommen haben. Wir informieren Dich, wenn die Spielzeit abgelaufen ist oder alle 
  Teilnehmer ihre Grimasse aufgenommen haben.</p>
  <p>Alle Fotos werden 24 Stunden nach dem Spiel gelöscht. Wir bitten alle 
  Spiele das Recht der Mitspieler zu wahren und keine Screenshots und Kopien 
  der Fotos zu machen.</p>
  <p>Fröhliches Grimassen schneiden!</p>
  `,
};
