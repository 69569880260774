import { consoleLog } from "../util/consoleLog";
import React, { useContext, useState } from "react";
import { GameContext } from "../gameContext";
import trackingEvent from "../util/tracking";
import MetaDecorator from "../util/metaDecorator";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import WallOfFame from "../support/wallOfFame";
import { writeUserSubscription } from "../util/apiConnect";
import BriefingI18n from "../support/briefingI18n";
import { useTranslation } from "react-i18next";

function Subscribe(props) {
  const { logInAs, setSubscribed, determineStoryboardStep } =
    useContext(GameContext);
  const [savingSubscribe, setSavingSubscribe] = useState("");
  const { t } = useTranslation();

  const subscribe = async (email) => {
    consoleLog.log(email);
    trackingEvent("other", "subscribe", "", "general");
    if (savingSubscribe === "saveError") {
      // if it doesn't work on first try, go back to the game
      determineStoryboardStep();
    }
    try {
      setSavingSubscribe("saving");
      await writeUserSubscription({
        email: email,
        subscribeFor: "general",
      });
      setSavingSubscribe("saved");
      setSubscribed(true);
      determineStoryboardStep();
      // consoleLog.log(`email: ${email}`);
    } catch (err) {
      trackingEvent("nonFatal", "exception", "subscribeAfterGame", err);
      setSavingSubscribe("saveError");
    }
  };

  function labelSubscribe() {
    if (savingSubscribe === "saving") return t("subscribe.saving");
    else if (savingSubscribe === "saveError")
      return t("subscribe.error_subscribe");
    else return t("subscribe.button_subscribe");
  }

  return (
    <Grid container rowSpacing={1}>
      <MetaDecorator
        title={t("subscribe.meta_title")}
        description={t("subscribe.meta_description")}
      />
      <Grid item xs={12}>
        <BriefingI18n briefing="subscribe" />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            subscribe(logInAs);
          }}
          style={{ width: "100%" }}
        >
          {labelSubscribe()}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={determineStoryboardStep}
          style={{ width: "100%" }}
        >
          {t("subscribe.button_back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WallOfFame itemCount={5} />
      </Grid>
    </Grid>
  );
}
export default Subscribe;
